import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const HostGuestSubscriptionExpireModal = (props) => {
  const [hostSubscription, setHostSubscription] = useState(true);
  // const navigate = useNavigate();

  // const handleNavigate = () => {
  //   navigate("/hostsubscribe");
  // };

  return (
    <>
      <Modal
        show={hostSubscription}
        centered
        scrollable
        size="md"
        className="modal_style host_guest_subsModal"
      >
        <Modal.Header className="border-0 pb-0">
          <button
            type="button"
            className="btn close close_modal"
            onClick={() => props.onDismiss()}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="content_sec">
            <h4 className="headerTxt text-center mb-0">
              Host & Guest Subscription
            </h4>
            {/* <p className="des text-center mb-0 mt-4">
              Your Subscription plan for "Host" has been expired. All your
              listing will be unlisted subscribe to get it back.
            </p> */}
            <p className="des text-center mb-0 mt-3">
              Both Your Host & Guest Subscription plan has been expired.
            </p>
            <p className="des text-center mb-0">
              To get your listing back & discount in book, Subscribe again.
            </p>

            <div className="mt-4 mb-3 gap-3 d-flex align-items-center justify-content-center">
              <Link to="/hostsubscribe" className="">
                <Button
                  className="subs_btn cmn-bg-orange"
                  // onClick={handleNavigate}
                  onClick={() => props.onDismiss()}
                >
                  Subscribe Host
                </Button>
              </Link>
              <Link to="/guestsubscribe" className="">
                <Button
                  className="subs_btn cmn-bg-orange"
                  // onClick={handleNavigate}
                  onClick={() => props.onDismiss()}
                >
                  Subscribe Guest
                </Button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HostGuestSubscriptionExpireModal;
