import React, { Component, useEffect, useState } from "react";

import Scrollbars from "react-custom-scrollbars";
import { Form } from "react-bootstrap";
import "../../../Assets/css/header.css";

import img1 from "../../../Assets/images/where/img1.png";
import img2 from "../../../Assets/images/filter/bed.png";

import place from "../../../Assets/images/CreateRoom/place.png";

const GuestCount = (props) => {
  const [count, setCount] = useState(0);
  const [countArray, setCountArray] = useState([]);

  useEffect(() => {
    let dataArr = [];
    props.data.content.map((val) => {
      dataArr.push(val);
    });
    localStorage.getItem("floorPlan")
      ? setCountArray(JSON.parse(localStorage.getItem("floorPlan")))
      : setCountArray(dataArr);
  }, [countArray]);

  const setData = (val, type, index) => {
    console.log("setData" , val);
    if (type == "add") {
      val.count = parseInt(val.count ? val.count : 0) + 1;
      setCount(val.count);
    } else if ((type == "sub") & (val.count > 0)) {
      val.count = val.count ? parseInt(val.count) - 1 : 0;
      setCount(val.count);
    }
    countArray[index] = val;
    props.onGuestCount(countArray);
  };

  useEffect(() => {
    localStorage.getItem("floorPlan")
      ? props.onGuestCount(JSON.parse(localStorage.getItem("floorPlan")))
      : localStorage.removeItem("floorPlan");
  }, []);

  const RoomListing = [
    {
      id: 1,
      name: "Bedrooms",
      count: 2,
    },
    {
      id: 2,
      name: "Bathrooms",
      count: 2,
    },
    {
      id: 3,
      name: "Common areas",
      count: 2,
    },
    {
      id: 4,
      name: "Beds",
      count: 5,
    },
  ];
  return (
    <div className="col-lg-12 col-xl-12 col-md-12">
      <div className="header-txt">
        <h2 className="fw-800">Share some basics about your place</h2>
        <p className="des-txt">
          Add all the rooms guests can use - even shared areas
        </p>
      </div>
      <div className="row mt-4 mb-4">
        <div className="col-12 col-sm-12 col-md-6 col-lg-8 order-2 order-md-1">
          <h4 className="mb-0 fw-700">Rooms in your listing</h4>
          {countArray &&
            countArray.map(
              (val, index) =>
                val?.status != "Inactive" && (
                  <>
                    <div className="roomListings mt-3">
                      <p className="mb-0 fw-600 orange-txt">{val.type}</p>
                      <div className="d-flex align-items-center justify-content-start mt-2">
                        <div
                          onClick={() => {
                            setData(val, "sub", index);
                          }}
                          className={
                            ((val.type == "guests" ||
                              val.type == "beds" ||
                              val.type == "bathrooms" ||
                              val.type == "Wifi" ||
                              val.type == "Parking" ||
                              val.type == "bedrooms") &&
                              val.count < 1) ||
                            val.count < 0
                              ? "cmn-Inc-Dec disabledbox"
                              : "cmn-Inc-Dec"
                          }
                        >
                          <i
                            className="fa fa-minus"
                            aria-hidden="true"
                            disabled={val.count < 0}
                          />
                        </div>
                        <div
                          className="f-16 fw-500 mx-2"
                          value={
                            countArray?.[index]
                              ? countArray[index]?.count
                              : val?.count < 0
                          }
                        >
                          {" "}
                          <span>
                            {countArray?.[index]
                              ? countArray?.[index]?.count
                              : val?.count < 0}{" "}
                          </span>
                          <span className="ms-1">{val.type}</span>
                        </div>
                        <div
                          onClick={() => {
                            setData(val, "add", index);
                          }}
                          className="cmn-Inc-Dec"
                        >
                          <i className="fa fa-plus" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                  </>
                )
            )}
            {console.log("RoomListing_data", RoomListing)}
          {/* {RoomListing && RoomListing.map((item, index) => {
            return (
              <div className="roomListings mt-3">
                <p className="mb-0 fw-600 orange-txt">{item.name}</p>
                <div className="d-flex align-items-center justify-content-start mt-2">
                  <div className="cmn-Inc-Dec">
                    <i className="fa fa-minus" aria-hidden="true" />
                  </div>
                  <div className="f-16 fw-500 mx-2">
                    {" "}
                    <span>{item.count}</span>
                    <span className="ms-1">{item.name}</span>
                  </div>
                  <div className="cmn-Inc-Dec">
                    <i className="fa fa-plus" aria-hidden="true" />
                  </div>
                </div>
              </div>
            );
          })} */}
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 order-1 order-md-2 d-flex align-items-center justify-content-center mb-4 mb-md-0">
          <img src={place} className="img-fluid" />
        </div>
      </div>
    </div>
  );
};
export default GuestCount;
