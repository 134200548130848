import React, { useState, useEffect } from "react";
import {
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { FRONT_URL } from "../../config/env";
import { toast } from "react-hot-toast";

function Confirmguestsubscribepay() {
  const [message, setMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const handlepay = async () => {
    // toast.success(
    //   "Subscription plan has been successfully added to your account."
    // );
    const { error } = await stripe.confirmPayment({
      elements,

      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${FRONT_URL}/subscriptions-list`,
      },
    });

    if (error) {
      setMessage(error?.message);
    } else {
      console.log('else called')
      // toast.success("Guest subscribed successfully!..");
      toast.success(
        "Subscription plan has been successfully added to your account."
      );
    }
  };

  return (
    <>
      <LinkAuthenticationElement id="link-authentication-element" />
      <PaymentElement id="payment-element" />
      {message && <p className="mt-3 text-danger">{message}</p>}
      <div className="text-right">
        <button onClick={() => handlepay()} className="btn btn-theme mt-3">
          Pay Now{" "}
        </button>
      </div>
    </>
  );
}

export default Confirmguestsubscribepay;
