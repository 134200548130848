import React, { Component, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Modal, Button, Form } from 'react-bootstrap'
import DataTable from "react-data-table-component";



const NightModal = (props) => {

    const [infoNightModal, setInfoNightModal] = useState(true);
    const [bookingdates, setBookingDate] = useState([]);
    const [amountperday, setAmountPerDay] = useState('')


    useEffect(() => {
        let alldates = getDatesInRange(props?.startDate, props?.endDate)
        setBookingDate(alldates);
        let amountperday = Number(props?.rentAmount) / Number(props?.days);
        setAmountPerDay(amountperday)
    }, [])


    function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate?.getTime());
        const dates = [];
        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);

        }
        return dates;
    }



    const columns = [
        {
            name: "Check-In",
            selector: (row) => new Date(row?.startDate)?.toLocaleDateString(),
        },
        {
            name: "Check Out",
            selector: (row) => new Date(row?.endDate)?.toLocaleDateString(),
        },
        {
            name: "Price",
            selector: (row) => row?.price,
        }
    ];

    const MblData = [
        {
            checkin: "12/12/20223",
            checkout: "email@flutterpads.com",
            price: "$585",
        },
    ];

    return (

        <Modal show={infoNightModal} centered scrollable className='modal_style'>
            <Modal.Header className='border-0 pb-0'>
                <button type="button" className="btn close close_modal" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
            </Modal.Header>
            <Modal.Body>
                <h6>Booking Night(s)</h6>

                <div className='mt-4'>

                    {/* {props?.spl?.length > 0 ? props?.spl?.map((dat) =>
                        <div className='shadowbox p-3 mb-3'>
                            <h6 className='mt-2'>Check-in Date</h6>
                            <p className='f-14 mb-2'>{new Date(dat?.startDate)?.toLocaleDateString()}
                            </p>

                            <h6 className='mt-2'>Check-out Date</h6>
                            <p className='f-14 mb-2'>{new Date(dat?.endDate)?.toLocaleDateString()}
                            </p>
                            <h6 className='mt-2'>Price</h6>
                            <p className='f-14 mb-2'>{dat?.price}
                            </p>
                        </div>) : <p className='text-danger'><b>Special price not found for selected dates!</b></p>} */}

                    <DataTable
                        className="cmn-data-table"
                        pagination
                        columns={columns}
                        data={props?.spl}
                    />
                </div>

                <div className='text-end'>
                    <Button className='btn-theme' onClick={() => props.onDismiss()}>Done</Button>
                </div>

            </Modal.Body>
        </Modal>



    )
}

export default NightModal