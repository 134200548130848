import React, { useEffect, useState, useRef } from "react";
import GoogleMaps from "../Maps/GMaps.js";
import RoomMap from "../Maps/RoomMap";
import { ProgressBar, Button, Form, InputGroup } from "react-bootstrap";
import FilterExp from "./FilterExp.js";
import { FaPlay } from "react-icons/fa6";
import { BsFillPauseFill } from "react-icons/bs";
import { Freeze } from "react-freeze";
import moment from "moment";
import MyMapComponent from "./MyMapComponent";
import { IoIosTime } from "react-icons/io";
import detailimg1 from "../../Assets/images/detail/detailimg1.png";
import detailimg2 from "../../Assets/images/detail/detailimg2.png";
import detailimg3 from "../../Assets/images/detail/detailimg3.png";
import detailimg4 from "../../Assets/images/detail/detailimg4.png";
import detailimg5 from "../../Assets/images/detail/detailimg5.png";
import guest1 from "../../Assets/images/landingPage/card/guest.png";
import bed1 from "../../Assets/images/landingPage/card/bed.png";
import bathroom1 from "../../Assets/images/landingPage/card/bathroom.png";
import bed2 from "../../Assets/images/landingPage/bed.png";
import bathroom2 from "../../Assets/images/landingPage/bath.png";
import clock from "../../Assets/images/icons/new/clock.png";
import ball from "../../Assets/images/icons/new/ball.png";
import rain from "../../Assets/images/icons/new/rain.png";
import dot from "../../Assets/images/icons/new/dot.png";
import { BiSolidLeftArrow } from "react-icons/bi";
import CoverModal from "../Common/Modals/CoverModal.js";
import PlaceOffer from "../Common/Modals/PlaceOffer.js";
import HealthSafety from "../Common/Modals/HealthSafety.js";
import AvailabilityModal from "./AvailabilityModal.js";

import AvailCont from "../Common/AvailCont.js";
import ShowAllImg from "../Common/Modals/ShowAllImg.js";

import wifi from "../../Assets/images/icons/icons/wifi.png";
import ac from "../../Assets/images/icons/icons/ac.png";
import tv from "../../Assets/images/icons/icons/tv.png";
import hairdryer from "../../Assets/images/icons/icons/hairdryer.png";
import food from "../../Assets/images/icons/icons/food.png";
import bed from "../../Assets/images/icons/icons/bed.png";

import Cancellation from "../Common/Modals/CancellationModal.js";

import pool from "../../Assets/images/icons/icons/pool.png";

import key from "../../Assets/images/key.png";
import card from "../../Assets/images/card.png";
import badge from "../../Assets/images/badge.png";

import prof from "../../Assets/images/prof.jpg";
import prof1 from "../../Assets/images/prof1.jpg";
import prof2 from "../../Assets/images/prof2.jpg";
import prof3 from "../../Assets/images/prof3.jpg";

import ReviewModal from "../Common/Modals/ReviewModal.js";
import {
  getAdminDetails,
  getAllHostingDetailhooks,
  getHostedDatas,
  getOverAllRating,
  getRoomRating,
  getRoomUsingId,
} from "../../Hooks/useUserHostingHook.js";
import { API_URL, toastOptions } from "../../config/env.js";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getguestusersubsctiptionhooks,
  getHostDetails,
  getstaydayhooks,
  getsubscriptiondetailshooks,
  getUserDatas,
  getUserEmail,
  getusersdatahooks,
  reserveRoom,
} from "../../Hooks/useUserHook.js";
import { getPrice } from "../../Hooks/useUserHook.js";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { toast, Toaster } from "react-hot-toast";
import Login from "../Common/Modals/LoginModal";
import GiveReviewModal from "../Common/Modals/GiveReviewModal.js";
import AddressModal from "../Common/Modals/AddressModal.js";
import { GOOGLE_MAP_API_KEY } from "../../config/env.js";
import HostHeader from "../BecomeHost/AfterHost/HostHeader.js";
import { Arrayofdate } from "../../config/Userhlpr.js";
import CustomFilterHeader from "../BecomeHost/AfterHost/CustomFilterHeader.js";
import GiveReview from "../Common/Modals/GiveReivew.js";
import { addwishlist } from "../../Hooks/filterHook.js";
import Cookies from "universal-cookie";
import NewNavbar from "./NewNavbar.js";
import PropertyNavHeader from "./PropertyNavHeader.js";
const Header = React.lazy(() => import("../Common/Header"));
const Footer = React.lazy(() => import("../Common/Footer"));

const Card = React.lazy(() => import("./Card"));
const cookies = new Cookies();

// const Datepicker = React.lazy(() => import('../Common/Datepicker/Datepicker.js'));

const Experiencedetail = (props, { shouldSuspendRendering }) => {
  const initialText =
    "We had great time with Akash! The meals we prepared were delicious and I'm hoping to be able to recreate them at home. Akash is very knowledgeable and a super likable guy. We had great time with Akash! The meals we prepared were delicious";
  const [theSpace, setTheSpace] = useState(initialText);
  const [showone, setShowone] = useState(false);
  const [showtwo, setShowtwo] = useState(false);
  const [showthree, setShowthree] = useState(false);
  const [showfour, setShowfour] = useState(false);
  const [readmore, setReadmore] = useState(false);

  const [cover, setCover] = useState(false);
  const [placeoffer, setPlaceOffer] = useState(false);
  const [healthsafety, setHealthSafety] = useState(false);
  const [avail, setAvail] = useState(false);
  const [showallimg, setShowAllImg] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [roomDetails, setRoomDetails] = useState({});
  const [review, setReview] = useState(false);
  const [bedCount, setBathCount] = useState(0);
  const [bedRoomCount, setBedRoomCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rentAmount, setRentAmount] = useState(0);
  const [daysDiff, setDaysDiff] = useState(0);
  const [bookingDetails, setBookingDetails] = useState({});
  const [adminDetails, setAdminDetails] = useState([]);
  const [email, setEmail] = useState("");
  const [isRefundable, setIsRefundable] = useState(false);
  const [guestCount, setGuestCount] = useState({});
  const [userRoomDatas, setUserRoomDatas] = useState({});
  const [roomId, setRoomId] = useState("");
  const [serviceFee, setServiceFee] = useState(0);
  const [serviceFeeTotal, setServiceFeeTotal] = useState(0);
  const [guest, setGuest] = useState(0);
  const [overAllRating, setOverAllRating] = useState({});
  const [ratings, setRatings] = useState({});
  const [hostDetails, setHostDetails] = useState({});
  const [showlog, setShowLog] = useState(false);
  const [position, setPosition] = useState({});
  const [coordsLoaded, setCoordsLoaded] = useState(false);
  const [exludedates, setExcludedates] = useState([]);
  const [specialdates, setSpecialdates] = useState([]);
  const [daterefresh, setDaterefresh] = useState(true);
  const [givereview, setGiveReview] = useState(false);
  const [isExcluded, setExcluded] = useState(false);
  const [userdatas, setUserdatas] = useState({});
  const [defaultprice, setDefaultprice] = useState(0);
  const [suboffer, setSuboffer] = useState(null);
  const [subofferamount, setSubofferamount] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [hostingdata, setHostingData] = useState([]);
  const [normalrentamount, setNormalRentAmount] = useState(0);
  const [carddata, setCardData] = useState([]);
  const [normalpercentage, setNormalPercentage] = useState("");
  const [guestcountdata, setGuestCountData] = useState(0);
  console.log("setGuestCountData", guestcountdata, suboffer);
  const [wishref, setWishref] = useState(1);

  const navigate = useNavigate();

  console.log("start date data ", startDate, "----enddate", endDate);
  const [days, setDays] = useState({});

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const [description, setDescription] = useState("")
  const VideoSrc =
    "https://player.vimeo.com/progressive_redirect/playback/682048671/rendition/360p?loc=external&oauth2_token_id=57447761&signature=5be12c7ba41b540b1bb6e8ff3cb8f859e9381ece088946bb8257fe3da7201f79";

  console.log("exludedates_exludedates", exludedates);

  const cancellationPolicy = (data) => {
    setIsRefundable(data);
  };
  const guestcount = (val) => {
    // props.onSetGuest(guest);
    setGuestCount(val);
  };

  const GetTime = (date) => {
    let hours = new Date(parseInt(date)).getHours();
    let min = new Date(parseInt(date)).getMinutes();
    let noon = ""
    if(hours >= 12){
      noon = " PM"
    }
    else {
      noon = " AM";
    }
    return hours?.toString()?.padStart(2 , 0)+":"+min?.toString()?.padStart(2 , 0) + noon;
  }

  const bookRoom = async () => {
    const data = {
      email: email,
      roomId: roomDetails._id,
      hostEmail: roomDetails.email,
      roomTitle: roomDetails.title,
      checkInDate: startDate,
      checkOutDate: endDate,
      totalAmount: parseInt(rentAmount) + parseInt(serviceFee),
      serviceFeeAmount: parseInt(serviceFee),
      nights: daysDiff,
      isVerified: false,
      guestCount: guestCount,
      isRefundable: isRefundable,
      accommodationAddress: roomDetails.location,
    };
    console.log("bookroom", data);
    if (startDate && endDate && guestCount && rentAmount && daysDiff) {
      await reserveRoom(data).then((val) => {
        console.log("data from reserve room hook :", val);
      });
    } else {
      toast.error("complete all details to reserve!", toastOptions);
    }
  };

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      setTimeout(() => {
        document.getElementById("play_pause_btn").style.opacity = 0;
      }, 1000);
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      document.getElementById("play_pause_btn").style.opacity = 1;
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const getHostingId = () => {
    const id = window.location.pathname.split("/")[2];
    console.log(
      "window.location.pathname",
      window.location.pathname.split("/")[2]
    );
    setRoomId(id);
    getRoomUsingId(id).then((data) => {
      console.log("getRoomUsingId in experience details page", data);
      const bedrooms = data.floorPlan.find((data) => {
        return data ? data.type == "Bedroom " : "";
      });
      console.log("bedroom count:", bedrooms, data?.descriptionDetails);
      setBedRoomCount(bedrooms?.count);
      setDescription(data?.descriptionDetails)
      const guest = data.floorPlan.find((data) => {
        return data.type == "guest";
      });
      console.log("guest asdfasdfsdfasfdasdf", guest?.count);
      setGuestCountData(guest?.count);
      guestcount(guest?.count);
      const bathroom = data.floorPlan.find((data) => {
        return data ? data.type == "Bathroom" : "";
      });
      console.log("bed count:", bathroom?.count);
      setBathCount(bathroom?.count);
      setRoomDetails(data);

      getHostedDatas("", "", "").then((data) => {
        console.log("datasdfasdfasd", data);
        setHostingData(data);
      });
      let exclued = Arrayofdate(data?.bookedDates).then((data) => {
        console.log("dddddddddddddd", data);
        setExcludedates(data);
      });
      getHostDetails(data.email).then((data) => {
        console.log("dataSSSSSSSSSSSSSS", data);
        setHostDetails(data.data);
      });
    });
    getOverAllRating(id).then((data) => {
      setOverAllRating(data.data);
    });
    getRoomRating(id).then((data) => {
      setRatings(data.data);
    });
    const data = getUserEmail();
    getusersdatahooks(data).then((dat) => {
      setUserdatas(dat);
    });

    // setUserDatas(userdata);
    // setUserdatas(getUserDatas());
  };

  const addressFunc = (data) => {
    console.log(data);
  };

  useEffect(() => {
    // getAllHostingDetailhooks
    async function fetchData() {
      if(getUserEmail()){
        let res = await getAllHostingDetailhooks({
          email : getUserEmail(),
          skip : 0,
          limit : 10
        });
        let filter = res?.filter((el) => el?._id != roomDetails?._id);
        console.log("filtttttttt", filter);
        console.log("resfasdfasdf", res);
        setCardData(filter);
      }
    }
    fetchData();
  }, [roomDetails]);

  const getData = () => {
    getadmindetails();
  };

  const getadmindetails = () => {
    getAdminDetails().then((data) => {
      console.log("admin details :", data);
      setAdminDetails(data);
      const serviceFeePercentage = data.filter((e) => e.name == "servicefee");
      console.log("serviceFeePercentage", serviceFeePercentage[0]);
      // setServiceFee(serviceFeePercentage[0]?.percentage);
    });
  };

  const handleDateChange = (date) => {
    // debugger
    console.log("new dates", date);
    // date = new Date(date);
    console.log(
      "handle date change :",
      date,
      roomDetails?.checkintime,
      new Date(date),
      roomDetails?.checkintime,
      roomDetails?.checkouttime
    );
    // initial change: start by setting the startDate
    console.log(
      "conditon checking",
      !startDate && !endDate,
      startDate && endDate
    );
    var excludedMap = false;
    console.log("enterrr", startDate, endDate);
    if (!startDate && !endDate) {
      console.log("0000 enterrr");
      let nwdate = date?.setHours(
        new Date(parseFloat(roomDetails?.checkintime)).getHours(),
        new Date(parseFloat(roomDetails?.checkintime)).getMinutes()
      );
      console.log("new date", nwdate);
      setStartDate(date);
    } else if (startDate && !endDate && startDate < date) {
      console.log("111 enterrr");
      if (exludedates?.length > 0) {
        exludedates?.map((dat, i) => {
          console.log("date checking", dat, startDate, date);
          if (
            new Date(dat) > new Date(startDate) &&
            new Date(dat) < new Date(date)
          ) {
            setStartDate("");
            excludedMap = true;
          } else {
            // return
          }
        });
        if (excludedMap) return toast.error("In Between dates are booked!");
      }
      let nwdate = date?.setHours(
        new Date(parseFloat(roomDetails?.checkouttime)).getHours(),
        new Date(parseFloat(roomDetails?.checkouttime)).getMinutes()
      );
      setEndDate(date);

      console.log("new date", nwdate);
      // if(Number.isNaN(nwdate)){ console.log("nulll  date"); setEndDate(null); }
    }
    if (startDate && endDate) {
      console.log("2222 enterrr", endDate > startDate, endDate, startDate);
      if (endDate > startDate) {
        let nwdate = date?.setHours(
          new Date(parseFloat(roomDetails?.checkouttime)).getHours(),
          new Date(parseFloat(roomDetails?.checkouttime)).getMinutes()
        );
        console.log("startDate && endDate new date", nwdate);
        setStartDate(date);
        setEndDate(null);
      } else {
        toast.error("Choose Start date should be lesser than Enddate date");
      }
    }

    if (startDate && !endDate && startDate > date) {
      setStartDate(null);
      setEndDate(null);
      toast.error("Choose End date should be greater than Start date");
    }
  };

  const contacthost = () => {
    if (email) {
      let roomdatas = {
        id: roomId,
        email: email,
        firstName: hostDetails.firstName,
        lastName: hostDetails.lastName,
        responseTime: hostDetails.responseTime,
        profilePicName: hostDetails.profilePicName,
        profilePicPath: hostDetails.profilePicPath,
        hostEmail: roomDetails.hostEmail,
        serviceFee: parseInt(serviceFee),
        rentAmount: rentAmount,
        days: daysDiff,
        amountPerNight: roomDetails.price,
        startDate: startDate,
        endDate: endDate,
      };
      // roomdatas = JSON.stringify(roomdatas);
      console.log("roomDatas :", roomdatas);
      localStorage.setItem("contactDatas", JSON.stringify(roomdatas));
      window.location.href =
        window.location.origin + "/contacthost/" + roomDetails?.email;
    } else {
      setShowLog(true);
    }
  };

  const getCoordinates = async (address) => {
    try {
      if (
        address.split(",")[0] != undefined &&
        address.split(",")[0] != "undefined"
      ) {
        console.log(
          "address in geocoding :",
          address,
          typeof address,
          address.split(",")[0]
        );
        await fetch(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            address +
            "&key=" +
            GOOGLE_MAP_API_KEY
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("outputdata", data);
            if (data.results[0]) {
              const latitude = data.results[0].geometry.location.lat;
              const longitude = data.results[0].geometry.location.lng;
              console.log(
                "complete data from geocoding fetch",
                data,
                "lat lng from room address",
                { latitude, longitude }
              );
              setPosition({ lat: latitude, lng: longitude });
              setCoordsLoaded(true);
            }
          });
      }
    } catch (e) {
      console.log("error on map", e);
    }
  };

  useEffect(() => {
    console.log("roomdetai");
    if (roomDetails) {
      const address =
        roomDetails.location?.street +
        "," +
        roomDetails.location?.area +
        "," +
        roomDetails.location?.city +
        "," +
        roomDetails.location?.state;
      console.log(
        "roomDetails.location?.street",
        roomDetails.location?.street,
        roomDetails.location?.street != undefined,
        roomDetails.location?.street != "undefined"
      );
      if (
        (roomDetails.location?.street != undefined,
        roomDetails.location?.street != "undefined")
      ) {
        getCoordinates(address);
      }
    }
  }, [roomDetails]);

  useEffect(() => {
    console.log("props data", roomDetails.price);
    const mail = getUserEmail();
    setEmail(mail);

    getHostingId();
    getData();
  }, [showlog]);

  useEffect(() => {
    async function fetchData() {
      let data = await getsubscriptiondetailshooks();
      console.log("useEffect_getsubscriptiondata", data?.record, data?.record?.find((el) => el["type"] == "Guest"));
      let rec = data?.record?.find((el) => el["type"] == "Guest");
      let percentage = rec["offerpercentage"];
      console.log('percentagedta', percentage)
      setNormalPercentage(percentage);
    }
    fetchData();
  }, [props]);

  console.log("normal rent amount", normalrentamount)

  const DateDisplay = (date) => {
    return (
      String(new Date(date)?.getDate()) +
      String(new Date(date)?.getMonth()) +
      String(new Date(date)?.getFullYear())
    );
  };

  useEffect(() => {
    try {
      if (adminDetails && exludedates && exludedates?.length > 0) {
        var today = new Date();
        let sorting = exludedates.sort((a, b) => a - b);
        var setIntial = false;
        console.log("sorting_sorting_sorting", sorting);
        var tommorrow = new Date(
          new Date(today).setDate(new Date(today).getDate() + 1)
        );
        let endexclmatch = moment(new Date(sorting[1])).format("YYYY-DD-MM");
        var checkfinish = false;
        console.log(
          "1st condit",
          today,
          new Date(sorting[0]),
          today <= new Date(sorting[0]),
          String(endexclmatch),
          String(moment(tommorrow).format("YYYY-DD-MM")),
          String(endexclmatch) != String(moment(tommorrow).format("YYYY-DD-MM"))
        );
        console.log("startdate_enddate", today, tommorrow);
        console.log("11111 startdate_enddate", sorting[0], endexclmatch);
        console.log(
          "if conditioooooooo",
          today.getTime(),
          new Date(sorting[0]).getTime(),
          today.getTime() <= new Date(sorting[0]).getTime(),
          String(endexclmatch) !=
            String(moment(tommorrow).format("YYYY-DD-MM")),
          today <= new Date(sorting[0]) &&
            String(endexclmatch) !=
            String(moment(tommorrow).format("YYYY-DD-MM"))
        );
        console.log(
          "isv condition",
          DateDisplay(tommorrow),
          DateDisplay(sorting[0])
        );
        if (DateDisplay(tommorrow) < DateDisplay(sorting[0])) {
          console.log("eeeeeeadsfasdfasd");
          setStartDate(today);
          setEndDate(tommorrow);
          setIntial = false;
        } else {
          sorting &&
            sorting?.length > 0 &&
            sorting?.map((val, ind) => {
              console.log(
                "sortiiiiiii",
                new Date(sorting[ind]).getTime(),
                new Date(sorting[ind + 1]).getTime(),
                new Date(sorting[ind + 1]).getTime() != "NaN",
                new Date(sorting[ind + 1]).getTime() != NaN
              );
              var index1 = sorting[ind];
              var index2 = sorting[ind + 1];

              if (
                !checkfinish &&
                new Date(index1).getTime() != undefined &&
                new Date(index2).getTime() != undefined
              ) {
                let ldata =
                  Number(new Date(index2).getTime()) -
                  Number(new Date(index1).getTime());
                let expdays = Math.round(ldata / 86400000);
                console.log(
                  "111 sorting val return",
                  index1,
                  index2,
                  ldata,
                  expdays,
                  expdays > 2,
                  checkfinish
                );
                if (expdays > 2) {
                  console.log("before sdfasdfasdfds", index1);
                  var addingdate = new Date(index1).setDate(
                    new Date(index1).getDate() + 1
                  );
                  console.log("after sdfasdfasdfds", addingdate);
                  if (new Date().getTime() < addingdate) {
                    console.log("addingddddddddd", new Date(addingdate));
                    setStartDate(new Date(addingdate));
                    setEndDate(
                      new Date(
                        new Date(addingdate).setDate(
                          new Date(addingdate).getDate() + 1
                        )
                      )
                    );
                    checkfinish = true;
                  }
                }
              }
            });
        }
        if (!checkfinish && sorting?.length > 0 && setIntial) {
          let finishIndex = sorting[sorting?.length - 1].getTime();
          let finishIndex1 = sorting[sorting?.length - 1].getTime();
          console.log("finishInteeeeeeeee", finishIndex, finishIndex1);
          // ex : sorting nov20 2023, nov 21 2023
          //Now 2024 so check today date to sorting nov20 2023

          if (today.getTime() < finishIndex) {
            setStartDate(
              new Date(
                new Date(finishIndex).setDate(
                  new Date(finishIndex).getDate() + 1
                )
              )
            );
            setEndDate(
              new Date(
                new Date(finishIndex1).setDate(
                  new Date(finishIndex1).getDate() + 2
                )
              )
            );
          } else {
            setStartDate(today);
            setEndDate(tommorrow);
          }
        }
        console.log("holidays days exludedates", sorting);
        if (sorting && sorting?.length > 0) {
          let bookingdates = [];
          sorting &&
            sorting?.length > 0 &&
            sorting?.map((val) => {
              {
                console.log(
                  "holidaysvalsgal",
                  val,
                  moment(val).format("YYYY-DD-MM")
                );
              }
              return bookingdates.push({
                date: String(moment(val).format("YYYY-MM-DD")),
                holidayName: "UnAvailable For Booking",
              });
            });
          console.log("holidays days", bookingdates);
          setHolidays(bookingdates);
        }
      } else if (adminDetails) {
        var today = new Date();
        var tommorrow = new Date(
          new Date(today).setDate(new Date(today).getDate() + 1)
        );
        console.log("111 sorting val return", today, tommorrow);
        setStartDate(today);
        setEndDate(tommorrow);
      }
    } catch (e) {
      console.log("ueeffect_err", e);
    }
  }, [exludedates, adminDetails]);

  useEffect(() => {
    // debugger
    var subofr = suboffer;
    console.log("Startdate");
    if (startDate && endDate) {
      console.log(
        "startDate && endDate",
        startDate,
        endDate,
        new Date(endDate) > new Date(startDate)
      );
      if (new Date(endDate) > new Date(startDate)) {
        let daydiff = new Date(endDate).getTime() - new Date(startDate).getTime();
        daydiff = Math.ceil(daydiff / (1000 * 3600 * 24));
        console.log("daydiff>>>>>>>>>>>", startDate, endDate);
        let data = {
          roomId: roomId,
          startDate: startDate,
          endDate: endDate,
        };
        let formData = new FormData();
        formData.append("roomId", roomId);
        formData.append("startDate", new Date(startDate).getTime());
        formData.append("endDate", new Date(endDate).getTime());
        let roomprice;
        getPrice(formData)
          .then((data) => {
            console.log("get price result", data);
            if (data?.type == "date") {
              toast.error(data?.msg);
              setStartDate(null);
              setEndDate(null);
              return;
            }
            console.log("fasdfjasdgfjhasd", data);
            // let spl = data?.specialdates?.length > 0 ? data?.specialdates : [];
            setSpecialdates(data?.specialdates);
            if (data.status) {
              roomprice = parseInt(data.price);
            } else {
              console.log("enter this", roomDetails.price);
              roomprice = parseInt(roomDetails.price);
            }
            console.log("roompricedata", roomprice);

            setNormalRentAmount(parseInt(data.price));
            var rent = parseInt(data.price); //daydiff * roomprice;
            console.log(
              "daydiff :",
              daydiff,
              " parseInt(roomDetails.price)",
              roomDetails.price,
              "rent",
              rent
            );
            setDaysDiff(daydiff);
            // setServiceFee(serviceFee*daydiff);
            // const fee = (serviceFee / 100) * rent;
            // var fee = 0;
            // adminDetails?.map((data, i) => {
            //   let singlefee = parseFloat(data?.percentage / 100) * rent;
            //   fee = fee + singlefee;
            // });
            console.log("adminDetails_adminDetails", adminDetails);
            // return acc + parseFloat(obj.percentage)/100 * rent;
            console.log(
              "get guest user subscription getUserEmail()",
              getUserEmail()
            );
            if (getUserEmail()) {
              var guestpayload = {
                email: getUserEmail(),
              };
              getguestusersubsctiptionhooks(guestpayload).then((data) => {
                console.log(
                  "get guest user subscription in res",
                  userdatas,
                  userdatas?.guestsubscribedaycount,
                  data
                );
                if (
                  userdatas?.guestsubscribedaycount > 0 &&
                  data?.data?.record?.offerpercentage
                ) {
                  setDefaultprice(rent);
                  let percent =
                    (rent / 100) *
                    parseFloat(data?.data?.record?.offerpercentage);
                  console.log("get guest user subscription", rent, percent);
                  rent = rent - percent;
                  console.log("get guest user subscription", rent);
                  subofr = true;
                  setSubofferamount(percent);
                  setSuboffer(subofr);
                  setRentAmount(rent);
                  var fee = adminDetails?.reduce(function(acc, obj) {
                    return acc + (rent * parseFloat(obj.percentage)) / 100;
                  }, 0);
                  console.log("feedetaildata", fee);
                  setServiceFeeTotal(fee);
                } else {
                  subofr = false;
                }
                setSuboffer(subofr);
                var fee = adminDetails?.reduce(function(acc, obj) {
                  return acc + (rent * parseFloat(obj.percentage)) / 100;
                }, 0);
                console.log("feedetaildata", fee);
                setServiceFeeTotal(fee);
                setRentAmount(rent);
              });
              console.log("get guest user subscription", rent);
              setSuboffer(subofr);
              var fee = adminDetails?.reduce(function(acc, obj) {
                return acc + (rent * parseFloat(obj.percentage)) / 100;
              }, 0);
              console.log("feedetaildata", fee);
              setServiceFeeTotal(fee);
              setRentAmount(rent);
            } else {
              var fee = adminDetails?.reduce(function(acc, obj) {
                return acc + (rent * parseFloat(obj.percentage)) / 100;
              }, 0);
              console.log("feedetaildata", fee);
              setServiceFeeTotal(fee);
              setRentAmount(rent);
            }
          })
          .catch((e) => {
            console.log("errrrrrrrrr", e);
            // alert(JSON.stringify(e))
          });
        console.log("rooMdaetalssssss", roomDetails.legal);
      } else {
        toast.error("Choose Start date should be lesser than Enddate date");
      }
    }
  }, [startDate, endDate]);

  // useEffect(()=>{

  //     navigator.geolocation.getCurrentPosition((location) => {

  //         setCoordsLoaded(true);
  //         console.log("current location :",location.coords.latitude,location.coords.longitude);
  //         const pos = {lat:location.coords.latitude,lng:location.coords.longitude}
  //         setPosition(pos);

  //     })

  //   },[]);

  useEffect(() => {
    const elements = document.querySelectorAll(
      ".react-datepicker__day--holidays"
    );
    elements.forEach((element) => {
      element.removeAttribute("title");
    });
  });

  const wishlistarray = async (id, mail, title, filter) => {
    if (filter == "add") {
      toast.success(`${title} add your wishlist`, toastOptions);
    } else if (filter == "remove") {
      toast.error(`${title} remove your wishlist`, toastOptions);
    }
    // filter
    console.log("dsfasdfsadf", title);
    if (mail) {
      let payload = {
        roomid: id,
        emailid: mail,
      };
      var data = await addwishlist(payload);
      console.log("wishlist r eturn: ", data);
      let usr = btoa(JSON.stringify(data.data.record));
      localStorage.setItem("USRDT", usr);
      cookies.set(
        "User-Datas",
        data.data.record,
        { path: "/" },
        { expires: new Date(Date.now() + 600000) }
      );

      setWishref((wish) => wish + 1);
      var usrdata = await getusersdatahooks(mail);
      return usrdata;
    } else {
    }
  };

  return (
    <div className="expdetails">
      {avail && (
        <AvailabilityModal
          id={roomId}
          email={email}
          roomDetails={roomDetails}
          onSetGuestCount={guestcount}
          onSetCancellation={cancellationPolicy}
          serviceFee={parseInt(serviceFee)}
          rentAmount={rentAmount}
          days={daysDiff}
          amountPerNight={roomDetails.price}
          startDate={startDate}
          endDate={endDate}
          onDismiss={() => setAvail(false)}
          spl={specialdates}
          subofferamount={subofferamount}
          suboffer={suboffer}
          defaultprice={defaultprice}
        />
      )}
      {showlog && <Login onDismiss={() => setShowLog(false)} />}
      {cover && <CoverModal onDismiss={() => setCover(false)} />}
      {placeoffer && (
        <PlaceOffer
          data={roomDetails.amenities}
          onDismiss={() => setPlaceOffer(false)}
        />
      )}
      {healthsafety && (
        <HealthSafety onDismiss={() => setHealthSafety(false)} />
      )}

      {showallimg && (
        <ShowAllImg
          photos={roomDetails.photos}
          photosPath={roomDetails.photosPath}
          onDismiss={() => setShowAllImg(false)}
        />
      )}

      {cancel && <Cancellation onDismiss={() => setCancel(false)} />}

      {review && (
        <ReviewModal
          ratings={ratings}
          reviewDatas={roomDetails.review}
          overAllRating={overAllRating}
          onDismiss={() => setReview(false)}
        />
      )}
      {givereview && (
        <GiveReview
          roomid={roomDetails?._id}
          onDismiss={() => setGiveReview(false)}
          onreload = {() => getHostingId()}
        />
      )}
      {/* <Header /> */}

      <div className="property-header" style={{ marginTop: "0px" }}>
        {/* <HostHeader /> */}
        {/* <CustomFilterHeader /> */}
        {/* <NewNavbar /> */}
        <PropertyNavHeader />
      </div>
      {console.log("roomDetails_roomDetails", roomDetails)}
      <div className="detail_ful mt-3 mt-sm-3 mt-md-4 mt-lg-5">
        {console.log(
          "getUserEmail() && roomDetails.email != getUserEmail()",
          getUserEmail(),
          getUserEmail() && roomDetails.email != getUserEmail()
        )}
        {getUserEmail() && roomDetails.email != getUserEmail() && (
          <div className="mobileresevebtn exp-details_reserve_pop">
            <button
              className="btn btn-theme"
              onClick={() => {
                setAvail(true);
              }}
            >
              Reserve
            </button>
          </div>
        )}
        <div className="container container_custom">
          <div className="detail_header">
            {/* <Link to="" className="back_to_lisitng">
              <span
                className=""
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <BiSolidLeftArrow /> Back to listings
              </span>
            </Link> */}
            <div className="host_addr mt-3">
              <div>
                <p className="detail_heading">{roomDetails.title}</p>
                <h5 className="loc_Details mb-0">
                  <span>
                    {" "}
                    {roomDetails &&
                      roomDetails?.location &&
                      roomDetails?.location?.city}
                    ,{" "}
                    {roomDetails &&
                      roomDetails?.location &&
                      roomDetails?.location?.countrycode?.split("(")[0]}{" "}
                    .{" "}
                  </span>
                  <span className="member_price">
                    $
                    {Math.ceil(
                      Number(normalrentamount) -
                        (Number(normalrentamount) * Number(normalpercentage)) /
                          100
                    )}
                    /month Member-Price{" "}
                  </span>
                </h5>
              </div>
              <div>
                <img
                  src={require("../../Assets/images/favicon1.png")}
                  className="img-fluid logo"
                />
              </div>
            </div>
          </div>
          <div className="detail_images mt-3">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3 px-1 showinthis767">
                <img
                  src={
                    roomDetails.photos &&
                    `${API_URL}${roomDetails.photosPath}/${
                      roomDetails.photos[0].name
                    }`
                  }
                  className="img-fluid img_full_height"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="row sub-image">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 mb-lg-0 col">
                    <div className="px-1">
                      <img
                        src={
                          roomDetails.photos &&
                          `${API_URL}${roomDetails.photosPath}/${
                            roomDetails.photos[1].name
                          }`
                        }
                        className="img-fluid img_half_height"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 mb-lg-0 col">
                    <div className="mb-2 px-1">
                      <img
                        src={
                          roomDetails?.photos &&
                          `${API_URL}${roomDetails?.photosPath}/${
                            roomDetails?.photos[2]?.name
                          }`
                        }
                        className="img-fluid img_half_height"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 mb-lg-0 col">
                    <div className="mb-2 px-1">
                      <img
                        src={
                          roomDetails?.photos &&
                          `${API_URL}${roomDetails?.photosPath}/${
                            roomDetails?.photos[3]?.name
                          }`
                        }
                        className="img-fluid img_half_height"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mb-2 mb-lg-0 col">
                    <div
                      className="show-all-img px-1"
                      onClick={() => setShowAllImg(true)}
                    >
                      <img
                        src={
                          roomDetails.photos &&
                          `${API_URL}${roomDetails?.photosPath}/${
                            roomDetails?.photos[4]?.name
                          }`
                        }
                        className="img-fluid img_half_height img_half_last_img"
                      />
                      <span className="show_all_btn">View All Photos</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <button
              className="showallimg show-all-img-btn"
              onClick={() => setShowAllImg(true)}
            >
              Show all images
            </button> */}
          </div>
          <div className="d-flex justify-content-between">
              <p className="name themeclr fw-600 f-20 d-flex align-items-center">
                <span className="fa fa-star full_star me-1" />
                <span className="me-1">
                  {overAllRating &&
                    overAllRating.overAllReview &&
                    overAllRating.overAllReview.toFixed(1)}{" "}
                  ({overAllRating && overAllRating.users} reviews)
                </span>
              </p>
          </div>

          <div className="overview mb-4 mt-2">
            <h2 className="mb-0 fw-700">Overview</h2>
            <div className="my-3">
              <div className="card_ftr_secc mt-2">
                <div className="d-flex align-items-center ">
                  <img
                    src={guest1}
                    alt="guest"
                    className="me-2 img-fluid cmn_logo"
                  />
                  <span>{guestcountdata} guest</span>
                </div>
                <div className="d-flex align-items-center ">
                  <img
                    src={bed1}
                    alt="bed"
                    className="me-2 img-fluid cmn_logo"
                  />
                  <span>{bedRoomCount} bedrooms</span>
                </div>
                <div className="d-flex align-items-center ">
                  <img
                    src={bathroom1}
                    alt="bathroom"
                    className="me-2 img-fluid cmn_logo"
                  />
                  <span>{bedCount} bathrooms</span>
                </div>
              </div>
            </div>
            <p className="mb-0">
              {/* From flexible length stays and no security deposits to affordable
              rates in prime neighborhoods, our properties go through a strict
              in-person vetting process to ensure that the quality standards,
              amenities, and accuracy of property description meet and exceed
              our requirements. */}
              {description}
            </p>
          </div>
          <hr className="custom_overview_hr" />
          <div className="row mt-4">
            <div className="order-2 order-sm-1 col-xxl-8 col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12 leftcont">
              {/* <div className="d-flex">
                <div className="flex-grow-1 me-3">
                  <p className="detail_heading">
                    {roomDetails.privacyType} hosted by {hostDetails.firstName}{" "}
                    {hostDetails.lastName}
                  </p>

                 
                </div>
                <div className="flex-shrink-0">
                  <img
                    src={
                      hostDetails && hostDetails.profilePicturePath
                        ? API_URL + "/" + hostDetails.profilePicturePath
                        : prof
                    }
                    width="56"
                    height="56"
                    alt="Sample Image"
                    className="prof_img_round"
                  />
                </div>
              </div> */}

              <div className="detail_content py-2">
                {/* <div className="d-flex mb-3">
                  <div className="flex-shrink-0">
                    <img src={card} className="desc_icon" alt="Sample Image" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="name themeclr fw-600 f-14">Self check-in</p>
                    <p className="detail_desc_grey">
                      Check yourself in with the lockbox.
                    </p>
                  </div>
                </div> */}
                {/* <div className="d-flex mb-3">
                  <div className="flex-shrink-0">
                    <img src={badge} className="desc_icon" alt="Sample Image" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="name themeclr fw-600 f-14">
                      Muthukumar is a Superhost
                    </p>
                    <p className="detail_desc_grey">
                      Superhosts are experienced, highly rated hosts who are
                      committed to providing great stays for their guests.
                    </p>
                  </div>
                </div> */}
                {/* <div className="d-flex mb-3">
                  <div className="flex-shrink-0">
                    <img src={key} className="desc_icon" alt="Sample Image" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="name themeclr fw-600 f-14">
                      Great check-in experience
                    </p>
                    <p className="detail_desc_grey">
                      Check yourself in with the lockbox.
                    </p>
                  </div>
                </div> */}
              </div>
              {/* <hr /> */}
              {/* <p className="name themeclr fw-600 f-16 mb-2">What you'll do</p>
              <p className="detail_desc_grey mb-2">
                Every booking includes free protection from Host cancellations,
                listing inaccuracies, and other issues like trouble checking in.
              </p> */}
              {/* <p className="mb-3">
                {" "}
                <Button
                  onClick={() => setCover(true)}
                  variant="link"
                  className="link_blue_border ps-0"
                >
                  Learn more
                </Button>
              </p> */}
              {/* <p className="name themeclr fw-600 f-16 mb-2">Description</p>
              <p className="detail_desc_grey mb-3">
                {roomDetails.descriptionDetails}
              </p> */}
              {/* <p className="themeclr f-14 mb-2 fw-600">The space</p> */}
              {/* <p className='detail_desc_grey mb-2'>Fully furnished and neatly maintained with gas stove and cooking utensils...</p> */}
              {/* <p className="detail_desc_grey">
                {showone ? theSpace : theSpace.substring(0, 150)}
              </p>
              <p className="mb-3">
                <a
                  href="javascript:void(0)"
                  className="link_blue_border"
                  onClick={() => setShowone(!showone)}
                >
                  {showone ? "Show Less" : "Show More"}
                </a>
              </p>{" "} */}

              <h2 className="fw-700 mb-2">Rooms</h2>
              <div className="row mt-4 pb-3">
                <div className="col-12 col-sm-6 col-md-6 col-xl-6 col-lg-4 col-xxl-4 mb-3 mb-lg-4 mb-xxl-0">
                  <div className="card card_sleep">
                    <div className="card-body">
                      {/* <img src={bed} className="desc_icon" alt="Sample Image" /> */}

                      {/* {console.log(roomDetails.floorPlan.find((data)=>{return data.type=='bedrooms'}))} */}
                      <p className="name">
                        {bedRoomCount && bedRoomCount} Bed room
                      </p>
                      <p className="detail_desc_grey f-16">
                        {/* {bedCount && bedCount} double bed */}
                      </p>
                      <div className="room_det mt-2">
                        <img
                          src={bed2}
                          className="room_img img-fluid"
                          alt="bed"
                        />
                        <img
                          src={bathroom2}
                          className="room_img img-fluid"
                          alt="bathroom"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <hr /> */}
              <h2 className="fw-700 mb-2 mt-3">
                Amenities and Features
              </h2>
              <div className="amenties-card mt-4">
                {roomDetails.amenities &&
                  roomDetails.amenities.map((data, index) => (
                    <>
                      {index < 4 && data?.type ? (
                        <>
                          <div className="cmn-card">
                            <div className="d-flex align-items-center amenities">
                              {console.log(
                                "asdfasdfasdfasd",
                                `${API_URL}${"/description"}/${data.image}`
                              )}

                              <img
                                src={`https://api.flutterpads.com/${"adminImages/description/"}/${
                                  data.image
                                }`}
                                className="place_icon"
                                alt=""
                              />
                              {/* <span className="icon" /> */}
                              <span className="ms-2 type f-16">
                                {data.type}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
              </div>
              {/* <div className="pb-3">
                <button
                  type="button"
                  className="btn btn-theme filterbtn"
                  onClick={() => setPlaceOffer(true)}
                >
                  Show all{" "}
                  {roomDetails.amenities && roomDetails.amenities.length - 1}{" "}
                  amenities
                </button>
              </div> */}
              {/* <hr /> */}
              {/* { console.log("vidwo" , roomDetails?.photos[roomDetails?.photos?.length-1]?.name?.split(".")[1])} */}
              {/* {roomDetails?.video == "yes" &&
                roomDetails?.photos &&
                roomDetails?.photos[
                  roomDetails?.photos?.length - 1
                ]?.name?.split(".")[1] == "mp4" && (
                  <div className="video_div video_div_heigt">
                    <div className="position-relative videsec_rela">
                      <video
                        ref={videoRef}
                        src={`${API_URL}${roomDetails?.photosPath}/${
                          roomDetails?.photos[roomDetails.photos?.length - 1]
                            ?.name
                        }`}
                        // controls
                        width="100%"
                      />
                      <div className="position-absolute video-bg-btn">
                        <button onClick={togglePlayPause} id="play_pause_btn">
                          {isPlaying ? (
                            <BsFillPauseFill className="text-white pause-icon" />
                          ) : (
                            <FaPlay className="play-icon" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )} */}
              {/* <hr /> */}
              {/* 
              <p className="name themeclr fw-600 f-16 mb-2">
                {startDate && endDate
                  ? `${daysDiff} nights in ${roomDetails.title}`
                  : startDate && !endDate
                  ? "Add checkout date"
                  : "Add check-in time"}
              </p>
              <p className="detail_desc_grey f-12">
                {startDate
                  ? new Date(startDate).toLocaleDateString()
                  : "Starting Date"}{" "}
                -{" "}
                {endDate
                  ? new Date(endDate).toLocaleDateString()
                  : "Ending Date"}
              </p> */}

              <h2 className="mb-0 fw-700 mt-4">Booking Dates</h2>
<div className="checkInOut_time mt-2">
  <div className="">
    <p className="lable mb-0 d-flex align-items-center gap-1"><IoIosTime className="icon" />Check In</p>
    <p className="time mb-0">{GetTime(roomDetails?.checkintime)}</p>
  </div>
  <div>
    <p className="lable mb-0 d-flex align-items-center gap-1"><IoIosTime className="icon" /> Check Out</p>
    <p className="time mb-0">{GetTime(roomDetails?.checkouttime)}</p>
  </div>
</div>
              <div className="position-relative experience_calendar mt-4 no_border_picker">
                {console.log("exludedates", startDate, endDate)}
                {/* {holidays && holidays?.length > 0 ? (
                  <Datepicker
                    className=""
                    onChange={(date) => {
                      handleDateChange(date);
                    }}
                    // selectsStart={true}
                    monthsShown={2}
                    minDate={new Date()}
                    // shouldCloseOnSelect={false}
                    excludeDates={exludedates} //roomDetails.bookedDates
                    // selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    inline={true}
                    holidays={holidays}
                  />
                ) : ( */}
                  <Datepicker
                    className=""
                    onChange={(date) => {
                      handleDateChange(date);
                    }}
                    selectsStart={true}
                    monthsShown={2}
                    minDate={new Date()}
                    shouldCloseOnSelect={false}
                    excludeDates={exludedates} //roomDetails.bookedDates
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    inline={true}
                  />
                {/* )} */}
                {/* <div className="text-end pb-4">
                  <Button
                    variant="link"
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                    }}
                    className="link_blue_border"
                  >
                    Clear Dates
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="order-1 order-sm-2 col-xxl-4 col-xl-5 col-lg-5 col-md-4 col-sm-12 col-12 col_4_tiv rightcont_don_mob">
              <AvailCont
                id={roomId}
                email={email}
                onSetGuestCount={guestcount}
                roomDetails={roomDetails}
                onSetCancellation={cancellationPolicy}
                serviceFee={parseInt(serviceFeeTotal)}
                rentAmount={rentAmount}
                days={daysDiff}
                legal={roomDetails.legal}
                amountPerNight={roomDetails.price}
                startDate={startDate}
                endDate={endDate}
                spl={specialdates}
                subofferamount={subofferamount}
                suboffer={suboffer}
                defaultprice={defaultprice}
                exludedates={exludedates}
                normalrentamount={normalrentamount}
              />
            </div>
          </div>
          {/* <hr /> */}

          {/* -------------------------------------------------- */}

          <div>
            <div className="d-flex justify-content-between">
              <p className="name themeclr fw-600 f-20 d-flex align-items-center">
                <span className="fa fa-star full_star me-1" />
                <span className="me-1">
                  {overAllRating &&
                    overAllRating.overAllReview &&
                    overAllRating.overAllReview.toFixed(1)}{" "}
                  ({overAllRating && overAllRating.users} reviews)
                </span>
              </p>
              
               {roomDetails?.bookingInfo?.find((e) => e?.email == email) &&
                roomDetails?.email != email && ( 
                  <button
                    className="btn btn-theme"
                    onClick={() => setGiveReview(true)}
                  >
                    Add review
                  </button>
                )} 
            </div>
            <div className="row mt-4 reviews_row pb-5">
              <div className="col-12 col-md-5">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Cleanliness</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings &&
                        ratings.cleanliness &&
                        ratings.cleanliness * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.cleanliness
                        ? ratings.cleanliness.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Communication</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings &&
                        ratings.communication &&
                        ratings.communication * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.communication
                        ? ratings.communication.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Check-in</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings && ratings.checkIn && ratings.checkIn * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.checkIn
                        ? ratings.checkIn.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 offset-md-2">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Accuracy</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings && ratings.accuracy && ratings.accuracy * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.accuracy
                        ? ratings.accuracy.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Location</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={
                        ratings && ratings.location && ratings.location * 10 * 2
                      }
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.location
                        ? ratings.location.toFixed(1)
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="reviews_text">Value</p>
                  <div className="d-flex align-items-center">
                    <ProgressBar
                      now={ratings && ratings.value && ratings.value * 10 * 2}
                      className="progress_review"
                    />
                    <span className="ms-2">
                      {ratings && ratings.value ? ratings.value.toFixed(1) : 0}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="row">
                  {roomDetails &&
                    roomDetails.review &&
                    roomDetails.review.map((value, index) =>
                      index < 4 ? (
                        <>
                          <div className="col-12 col-md-6 mb-3">
                            <div className="d-flex mb-3">
                              <div className="flex-shrink-0">
                                <img
                                  src={
                                    hostDetails &&
                                    hostDetails.profilePicturePath
                                      ? API_URL +
                                        "/" +
                                        hostDetails.profilePicturePath
                                      : prof
                                  }
                                  className="desc_icon"
                                  alt="Sample Image"
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <p className="name themeclr fw-600 f-14">
                                  {value.name}
                                </p>
                                <p className="detail_desc_grey">
                                  {new Date(value.date).toLocaleDateString()}
                                </p>
                              </div>
                            </div>
                            <p className="detail_desc_grey">
                              {/* {showone?value.description:(value.description).substring(0,150)} */}
                              {value.description}
                            </p>
                            {/* <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={()=>setShowone(!showone)}>
                    {showone?"Show Less":"Show More"}
                        </a></p> */}
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    )}

                  {/* <div className='col-12 col-md-6 mb-3'>
                                <div className="d-flex mb-3">
                    <div className="flex-shrink-0">
                        <img src={prof1} className="desc_icon" alt="Sample Image" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <p className='name themeclr fw-600 f-14'>Charlotte</p>
                        <p className='detail_desc_grey'>June 2022</p>
                    </div>
                    </div>
                    <p className='detail_desc_grey'>
                        {showtwo?text:text.substring(0,150)}
                        </p>
                    <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={()=>setShowtwo(!showtwo)}>
                    {showtwo?"Show Less":"Show More"}
                        </a></p>
                    
                                </div>
                                <div className='col-12 col-md-6 mb-3'>
                                <div className="d-flex mb-3">
                    <div className="flex-shrink-0">
                        <img src={prof2} className="desc_icon" alt="Sample Image" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <p className='name themeclr fw-600 f-14'>Charlotte</p>
                        <p className='detail_desc_grey'>June 2022</p>
                    </div>
                    </div>
                    <p className='detail_desc_grey'>
                        {showthree?text:text.substring(0,150)}
                        </p>
                    <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={()=>setShowthree(!showthree)}>
                    {showthree?"Show Less":"Show More"}
                        </a></p>
                    
                    
                                </div>
                                <div className='col-12 col-md-6 mb-3'>
                                <div className="d-flex mb-3">
                    <div className="flex-shrink-0">
                        <img src={prof3} className="desc_icon" alt="Sample Image" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <p className='name themeclr fw-600 f-14'>Charlotte</p>
                        <p className='detail_desc_grey'>June 2022</p>
                    </div>
                    </div>
                    <p className='detail_desc_grey'>
                        {showfour?text:text.substring(0,150)}
                        </p>
                    <p className='mb-3'><a href="javascript:void(0)" className='link_blue_border' onClick={()=>setShowfour(!showfour)}>
                    {showfour?"Show Less":"Show More"}
                        </a></p>
                    
                                </div> */}
                  <div className="pb-3">
                    {roomDetails &&
                    roomDetails.review &&
                    roomDetails.review.length >= 2 ? (
                      <button
                        type="button"
                        className="btn btn-theme filterbtn"
                        onClick={() => setReview(true)}
                      >
                        Show all{" "}
                        {roomDetails && roomDetails.review
                          ? roomDetails.review.length
                          : 0}{" "}
                        reviews
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <hr className="custom_overview_hr" />

              <div>
                <h2 className="name themeclr fw-700 mb-2 pt-4 pb-4">
                  Location
                </h2>

                {coordsLoaded && (
                  <Freeze freeze={shouldSuspendRendering}>
                    <div className="mapComponent map_detail">
                      {/* <GoogleMaps position={position} onGetAddress={(val)=>addressFunc(val)} /> */}
                      {console.log("position_data", position , parseFloat(roomDetails?.lat) , parseFloat(roomDetails?.lng))}
                      <RoomMap 
                      // position={position} 
                      position ={ { lat: parseFloat(roomDetails?.location?.lat), lng: parseFloat(roomDetails?.location?.lng) }}
                      />
                    </div>
                  </Freeze>
                )}

                {/* <p className="detail_desc_grey py-4">
                  We will meet at my house and will cook in a well equiped
                  kitchen.
                  <br />
                  I would like to show you a special local market later after
                  the workshop.
                  <br />
                  To reach my place its best to use Uber or Ola taxi app its
                  very cost effective and convenient.
                </p> */}
                {/* <hr /> */}

                {/* <div className="row pb-5">
                  <div className="col-12 col-md-7">
                    <div className="d-flex mb-3">
                      <div className="flex-shrink-0">
                        <img
                          src={
                            hostDetails && hostDetails.profilePicturePath
                              ? API_URL + "/" + hostDetails.profilePicturePath
                              : prof
                          }
                          className="desc_icon"
                          alt="Sample Image"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="name themeclr fw-600 f-14">
                          {hostDetails.firstName}&nbsp;{hostDetails.lastName}
                        </p>
                        <p className="detail_desc_grey f-12 pb-1">
                          Host on FlutterPads since{" "}
                          {hostDetails &&
                            hostDetails.hostSince &&
                            new Date(hostDetails.hostSince).getFullYear()}
                        </p>
                        
                      </div>
                    </div>
                    <p className="detail_desc_grey f-12 pb-1">
                      {hostDetails && hostDetails.bio}
                    </p>
                  </div>
                  <div className="col-md-5">
                    <p className="detail_desc_grey f-12 pb-1">
                      {console.log(
                        "hostDetails.languagesKnown",
                        hostDetails.languagesKnown
                      )}
                      Language :{" "}
                      <span>
                        {hostDetails &&
                          hostDetails.languagesKnown &&
                          hostDetails.languagesKnown.join(", ")}
                      </span>
                    </p>
                    <p className="detail_desc_grey f-12 pb-1">
                      Response rate :{" "}
                      <span>
                        {hostDetails && hostDetails.responseRate
                          ? `${hostDetails.responseRate}%`
                          : `0%`}
                      </span>
                    </p>
                    {hostDetails && hostDetails.responseTime && (
                      <p className="detail_desc_grey f-12 pb-1">
                        Response time :{" "}
                        <span>{hostDetails && hostDetails.responseTime}</span>
                      </p>
                    )}

                    <Button
                      onClick={contacthost}
                      disabled={startDate == null || endDate == null}
                      className="btn-theme my-4 contact-host-btn"
                    >
                      Contact host
                    </Button>

                    <p className="detail_desc_grey f-12 pb-1">
                      To protect your payment, never transfer or communicate
                      outside of the FlutterPads website or app{" "}
                    </p>
                  </div>
                </div>

                <hr />
                <div className="pb-4">
                  <p className="name themeclr fw-600 f-16 mb-2 pt-4 pb-4 ">
                    Things to know
                  </p>
                  <div className="row thingstoknow">
                    <div className="col-md-4 mt-md-0 mt-3">
                      <p className="name themeclr fw-600 f-16 mb-2">
                        House rules
                      </p>
                      <p className="detail_desc_grey f-16 pb-1">
                        <img src={clock} /> Check-in: After 3.00 pm
                      </p>
                    </div>
                    <div className="col-md-4 mt-md-0 mt-3">
                      <p className="name themeclr fw-600 f-16 mb-2">
                        Health and safety
                      </p>

                      <p className="detail_desc_grey f-16 pb-1">
                        <img src={ball} />
                        COVID-19 safety practices apply
                      </p>
                      <p className="detail_desc_grey f-16 pb-1">
                        <img src={rain} />
                        Corbon monoxide alarm
                      </p>
                      <p className="detail_desc_grey f-16 pb-1">
                        <img src={dot} />
                        Smoke alarm
                      </p>
                      <Button
                        onClick={() => setHealthSafety(true)}
                        variant="link"
                        className="link_blue_border ps-0"
                      >
                        Show more
                      </Button>
                    </div>
                    <div className="col-md-4 mt-md-0 mt-3">
                      <p className="name themeclr fw-600 f-16 mb-2">
                        Cancellation Policy
                      </p>
                      <p className="detail_desc_grey f-16 pb-1">
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Ab non facere incidunt assumenda amet perferendis
                        quasi, consequatur distinctio voluptates voluptatibus
                        quas eius. Quidem consequuntur nulla harum rem odit
                        officia a.
                      </p>
                      <Button
                        variant="link"
                        className="link_blue_border ps-0"
                        onClick={() => setCancel(true)}
                      >
                        Show more
                      </Button>
                    </div>
                  </div>
                </div> */}

                <div className="feature_proper mt-5">
                  <div className="header_sec mb-4">
                    <div className="fst_sec">
                      <h2 className="fw-700">Our Featured Properties</h2>
                      <p>
                        Take a look at some of the most sought after properties
                        on FlutterPads
                      </p>
                    </div>
                    {/* <div className="snd_sec view-all-web">
                      <div className="view-all-properties">
                        <Button variant="primary">View all Properties</Button>
                      </div>
                    </div> */}
                  </div>

                  <div className="row mb-4 mt-0 mt-sm-4 our-featured-card">
                    {carddata &&
                      carddata?.length > 0 &&
                      carddata?.map((val) => (
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-3">
                          {console.log(
                            "valvalvalvalvalvalvalvalvalvalvalvalvalvalvalval",
                            val
                          )}
                          <Card data={val} symbol={""} userData={""} wishlistarray={wishlistarray}/>
                        </div>
                      ))}
                  </div>

                  <div className="snd_sec view-all-mobile mt-3 mb-4">
                    <div className="view-all-properties">
                      <Button variant="primary">View all Properties</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Toaster />
    </div>
  );
};

export default Experiencedetail;
