const BASE_URL = "api";
export const hostingfilterApi = BASE_URL + "/hosting-filter";
export const wishlistApi = BASE_URL + "/wishlist";
export const filtericonApi = BASE_URL + "/filter-icon";
export const iconfilterApi = BASE_URL + "/icon-filter";
export const filteroptionApi = BASE_URL + "/filter-option";
export const filterbuttonApi = BASE_URL + "/Filter-Button";
export const viewwishlistApi = BASE_URL+"/view-wishlist";
export const viewwishlistarrayApi = BASE_URL+"/view-wishlist-array";
export const experiencehostingfilterApi = BASE_URL + "/experience-hosting-filter";
export const hostavaliablebalanceApi = BASE_URL + "/user-avaliable-balance";
export const successbookingdataApi = BASE_URL + "/success-booking-data"; // finish
export const withdrawApi = BASE_URL + "/withdraw"; // finish
export const filterhostmapApi = BASE_URL + "/filter-hosting-map";
export const filterexperiencemapApi = BASE_URL + "/filter-experience-map";
export const getaboutcmsApi = BASE_URL + "/get-about-cms"; // finish