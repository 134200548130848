import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/images/logo1.png";
import { Button } from "react-bootstrap";
const NoSubscription = (props) => {
  return (
    <div className="no-subscription mt-3">
      <div className="plansrow_sub row mt-4 mt-xxl-5 mb-4 mb-lg-5">
        <div className="col-12 col-md-6 col-xl-4 d-flex justify-content-center align-items-center">
          <div className="subs_card">
            <img src={logo} alt="user-icon" className="user-icon-img " />
            <h6 className="no_subs_txt mb-0">
              No Subscription Plan Purchased Yet
            </h6>
            {/* <h6 className="font_pers mb-0">No Subscription</h6> */}
          </div>
        </div>
      </div>
      {/* <div className="text-center">
        <div className="subscribe_block">
          <Button className="subscribe_btn">
            <Link to={"/hostsubscribe"} className="text-decoration-none text-white">
              Subscribe for Host
            </Link>
          </Button>

          <p className="take-care-of my-2">or</p>

          <Button className="subscribe_btn">
            <Link to={"/guestsubscribe"} className="text-decoration-none text-white">
              Subscribe for Guest
            </Link>
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default NoSubscription;
