import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import Scrollbars from 'react-custom-scrollbars';

import detailimg1 from "../../../Assets/images/detail/detailimg1.png";
import detailimg2 from "../../../Assets/images/detail/detailimg2.png";
import detailimg3 from "../../../Assets/images/detail/detailimg3.png";
import detailimg4 from "../../../Assets/images/detail/detailimg4.png";
import detailimg5 from "../../../Assets/images/detail/detailimg5.png";

import { API_URL } from "../../../config/env";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const ShowAllImg = (props) => {
  const [showallimg, setShowAllImg] = useState(true);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Modal
        show={showallimg}
        
        centered
        scrollable
        size='md'
        className="modal_style modal_view_img"
      >
        <Modal.Header className="border-0 pb-0">
          <button
            type="button"
            className="btn close close_modal"
            onClick={() => props.onDismiss()}
          >
            <span aria-hidden="true" className="fa fa-times f-14" />
            <span class="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* <OwlCarousel
            className="owl-theme showallimg_carousal ms-2"
            dots={false}
            items={1}
            margin={24}
            // stagePadding={10}
            nav={true}
            
            id="big"
            responsive={{
              0: {
                items: 1,
              },
              600: {
                items: 1,
              },
              1000: {
                items: 1,
              },
            }}
          > */}
            <Slider {...settings} className="showallimg_slick">
            {props.photos.map(
              (data, index) => {
                if (data?.name?.split(".")[1] != "mp4") {
                  return (
                    <div className="item">
                      <img
                        src={`${API_URL}${props.photosPath}/${data.name}`}
                        className="img-fluid"
                      />
                    </div>
                  );
                }
              }
              //     {data?.name?.split(".")[1]!="mp4" &&<div className='item'>
              //         {console.log("`${API_URL}${props.photosPath}/${data.name}`",`${API_URL}${props.photosPath}/${data.name}` , data?.name?.split(".")[1]!="mp4")}
              //     <img src={`${API_URL}${props.photosPath}/${data.name}`} />
              //  </div>}
            )}
            </Slider>
          {/* </OwlCarousel> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShowAllImg;
