import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Form,
  Table,
  Dropdown,
  Toast,
  Button,
} from "react-bootstrap";
import HostHeader from "../../HostHeader";
import { toast } from "react-hot-toast";
import prof from "../../../../../Assets/images/prof.jpg";
import img1 from "../../../../../Assets/images/where/img1.png";
import Footer from "../../../../Common/Footer";
import DataTable from "react-data-table-component";
import {
  closedisputehook,
  disputechathook,
  disputechatwithimghook,
  getsinglechathook,
  getsingleuserdisputehook,
} from "../../../../../Hooks/adminHook";
import { getUserDatas } from "../../../../../Hooks/useUserHook";
import { useNavigate } from "react-router-dom";
import CreateDispute from "./Modal/CreateDisputeModal";
import { API_URL } from "../../../../../config/env";
// import user from "../../Assets/images/newLogo.png"
import user from "../../../../../Assets/images/newLogo.png";
import { GrNext, GrPrevious } from "react-icons/gr";
import HeaderDash from "../../../../Common/HeaderDash";
const Dispute = () => {
  const [showdispute, setShowDispute] = useState(false);
  const [dispute, setDispute] = useState([]);
  const [singledispute, setSingledispute] = useState({});
  // const [singleRecord, setSingleRecord] = useState({})
  // console.log('singleRecordsdata',singleRecord)
  const [image, setImage] = useState();
  const [imageshow, SetImageshow] = useState();
  const [message, setMessage] = useState();
  const [userdata, setUserdata] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchdata() {
      var userdata = await getUserDatas();
      await getsingleuserdisputehook(userdata?._id).then((result) => {
        console.log("get single user dispute hook", result);
        result?.data?.record?.map((data, i) => (data.sno = i + 1));
        setDispute(result?.data?.record);
        setUserdata(userdata);
      });
    }
    fetchdata();
  }, []);

  //     const [imageFiles, setImageFiles] = useState([]);
  //     const [previewUrl, setPreviewUrl] = useState([]);
  // const[filenames, setFileNames] = useState([])

  //     const HandleFile = (data) => {
  //         var file = data.target.files;
  //     Array.from(file).forEach((val,i)=>{ setFileNames([...filenames, val.name])
  //         })
  //    console.log("viji", filenames)
  //         var reader = new FileReader()
  //         if (file) {
  //             setImageFiles(file);
  //             let arr = [];
  //             Array.from(file).forEach((val)=>{
  //                 setPreviewUrl([...previewUrl,URL.createObjectURL(val)])
  //                 arr.push(URL.createObjectURL(val));
  //             });
  //             setPreviewUrl(arr);
  //         }
  //     }
  const handleview = async (data) => {
    console.log("data", data);
    setSingledispute(data);
  };
  const filevalidate = (image) => {
    var fileName = image?.name ? image?.name : image;
    var idxDot = fileName?.lastIndexOf(".") + 1;
    var extFile = fileName?.substr(idxDot, fileName.length).toLowerCase();
    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile == "webp"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const columns = [
    {
      name: "S.No.",
      sortable: true,
      reorder: false,
      cell: (data) => <>{data.sno}</>,
    },
    {
      name: "Ticket id",
      cell: (data) => <>{data.ticketid}</>,
      minWidth: "150px",
    },
    {
      name: "Raised Date",
      cell: (data) => <>{new Date(data?.createdAt)?.toLocaleDateString()}</>,
      minWidth: "200px",
    },
    // {
    //     name:"Location",
    //     cell: (data) => (
    //         <>
    //         <p>{data.rname} {data.rplace}</p>
    //         </>),
    //         minWidth:"200px"

    // },
    // {
    //     name:"Location",
    //     cell: (data) => (
    //         <>
    //         <p>{data.rname}</p>
    //         <p>{data.rplace}</p>
    //         </>),
    // },
    {
      name: "Status",

      button: true,
      cell: (data) => (
        <button
          type="button"
          class="btn f-13"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
        >
          {data?.status}
        </button>
      ),
      minWidth: "200px",
    },
    {
      name: "Option",
      button: true,
      cell: (data) => (
        <button
          onClick={() => {
            setShowDispute(true);
            handleview(data);
          }}
          type="button"
          class="btn cus-btn-link nowrap"
        >
          View Details
        </button>
      ),
    },
  ];
  const [createdispute, setCreateDispute] = useState(false);

  const handlesend = async () => {
    let userdata = await getUserDatas();
    let payload = {
      message: message,
      sender: userdata?._id,
      id: singledispute?._id,
    };
    if (filevalidate(image)) {
      payload.image = image;
      payload.folpath = singledispute?.attachment?.path;
      await disputechatwithimghook(payload).then(async (result) => {
        console.log("dispute chat with img hook result", result);
        await getsinglechathook(singledispute?.ticketid).then((data) => {
          console.log("data", data);
          setSingledispute(data?.data?.record[0]);
          setMessage("");
          setMessage("");
          setImage();
          SetImageshow();
        });
      });
    } else {
      await disputechathook(payload).then(async (result) => {
        console.log("dispute chat hook result", result);
        await getsinglechathook(singledispute?.ticketid).then((data) => {
          console.log("data", data);
          setSingledispute(data?.data?.record[0]);
          setMessage("");
          setImage();
          SetImageshow();
        });
      });
    }
  };

  // const getRecords = (data) =>
  // {
  //   console.log('datsgal',data)
  // }

  const MblData = [
    {
      sno: "#1",
      ticketId: "JTL37",
      raisedDate: "11/23/2023",
      status: "Raised",
    },
    {
      sno: "#2",
      ticketId: "JTL37",
      raisedDate: "11/23/2023",
      status: "Raised",
    },
    {
      sno: "#3",
      ticketId: "JTL37",
      raisedDate: "11/23/2023",
      status: "Raised",
    },
  ];

  return (
    <div className="dispute">
      {createdispute && (
        <CreateDispute onDismiss={() => setCreateDispute(false)} />
      )}
      <div>
        <HeaderDash />
        <HostHeader />
        <div className="container container_custom mh-100vh-header pb-5">
          <div
            className={
              showdispute ? "d-none" : "cmn-table-webView mt-4 mt-lg-5"
            }
          >
            <div className={showdispute ? "d-none" : "p-3"}>
              <div className="d-flex jc-between align-items-center">
                <h4 className="mb-0 themeclr1 fw-500"> </h4>
                <button
                  className="btn cmn-bg-orange px-3 fw-500"
                  onClick={() => setCreateDispute(true)}
                >
                  Create
                </button>
              </div>
            </div>

            <div className={showdispute ? "d-none" : "disputetab"}>
              <div className="position-relative no-data-bdr-top">
                <DataTable
                  className="dis_tab cmn-data-table"
                  pagination
                  columns={columns}
                  data={dispute}
                />
                {/* <div className="showing-records">Showing 3 of 3 records</div> */}
              </div>
            </div>
          </div>

          <div className={showdispute ? "d-none" : "cmn-table-mblView"}>
            <div className="d-flex jc-between align-items-center mb-3 mt-4">
              <h2 className="mb-0 themeclr1 fw-500">Disputes</h2>
              <Button
                className="btn cmn-bg-orange px-3 fw-500"
                onClick={() => setCreateDispute(true)}
              >
                Create
              </Button>
            </div>
            <div className="datas">
              <div className="row">
                {MblData.map((item, index) => {
                  return (
                    <div className="col-12">
                      <div className="table-datas">
                        <p className="mb-2 themeclr1 fw-500">{item.sno}</p>
                        <div className="mb-2">
                          <span className="name">S.No: </span>
                          <span className="value">{item.sno}</span>
                        </div>
                        <div className="mb-2">
                          <span className="name">Ticket ID: </span>
                          <span className="value">{item.ticketId}</span>
                        </div>
                        <div className="mb-2">
                          <span className="name">Raised Date: </span>
                          <span className="value">{item.raisedDate}</span>
                        </div>
                        <div className="mb-2">
                          <span className="name">Status: </span>
                          <span className="value">{item.status}</span>
                        </div>
                        <div className="mb-2">
                          <span className="name">Action: </span>
                          <Button className="view-details cus-btn-link-black">
                            View Details
                          </Button>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
              <div className="prev-next-btn">
                <Button className="">
                  <GrPrevious />
                </Button>
                {/* <span className="f-15 fw-400">Showing 3 of 3 records</span> */}
                <Button className="">
                  <GrNext />
                </Button>
              </div>
            </div>
          </div>

          <div className={showdispute ? "row my-4" : "d-none"}>
            <div className="col-lg-7">
              <h4 className="themeclr mb-3">
                <button
                  className="btn btn-link"
                  onClick={() => setShowDispute(false)}
                >
                  <span className="text-dark fa fa-chevron-left" />
                </button>
                Ticket id : {singledispute?.ticketid}
              </h4>
              {singledispute?.status != "closed" && (
                <div className="disputechart shadowbox br-10 ">
                  <Tabs
                    defaultActiveKey="home"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                  >
                    <Tab eventKey="home" title="Keep talking" className="p-4">
                      <Form.Control
                        as="textarea"
                        value={message}
                        placeholder="Leave a message here"
                        style={{ height: "100px" }}
                        onChange={(e) => {
                          setMessage(e?.target?.value);
                        }}
                      />
                      <div className="mt-3 text-end">
                        <button className="btn btn-theme" onClick={handlesend}>
                          Send
                        </button>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              )}
              <div className="disputechat-lists mb-4 mt-5 pe-4">
                <ul className="ps-0">
                  {singledispute?.chat?.map((data, i) =>
                    data?.ref == "supportteam" ? (
                      <>
                        <li className="mb-3 reply">
                          <div className="d-flex gap-4">
                            <div>
                              <img src={user} className="chatprof" />
                              <p className="text-center f-14">Admin</p>
                            </div>
                            <div className="w-full">
                              <p className="f-14">{data?.message}</p>
                            </div>
                          </div>
                        </li>
                      </>
                    ) : (
                      data?.ref == "user" && (
                        <li className="mb-3 message">
                          <div className="d-flex gap-4">
                            <div className="w-full">
                              {data?.image && (
                                <a
                                  href={
                                    API_URL +
                                    singledispute?.attachment?.path +
                                    "/" +
                                    data?.image
                                  }
                                  target="_blank"
                                >
                                  <img
                                    className="attached attach_img_chat"
                                    src={
                                      API_URL +
                                      singledispute?.attachment?.path +
                                      "/" +
                                      data?.image
                                    }
                                  />
                                </a>
                              )}
                              <p className="f-14">{data?.message}</p>
                            </div>
                            <div>
                              <img
                                src={API_URL + userdata?.profilePicturePath}
                                className="chatprof"
                              />
                              <p className="text-center f-14">You</p>
                            </div>
                          </div>
                        </li>
                      )
                    )
                  )}
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="dispute-right">
                <h5 className="themeclr">Dispute Reason</h5>
                <p className="f-18">{singledispute?.description}</p>
                {/* <p className='f-14 mt-3 mb-2'>Reservation Information</p> */}
                <div className="resortimg mt-4">
                  <h5 className="themeclr">Dispute Image</h5>
                  <a
                    href={
                      API_URL +
                      singledispute?.attachment?.path +
                      "/" +
                      singledispute?.attachment?.name
                    }
                    target="_blank"
                  >
                    <img
                      src={
                        API_URL +
                        singledispute?.attachment?.path +
                        "/" +
                        singledispute?.attachment?.name
                      }
                    />
                  </a>
                  <img
                    src={API_URL + userdata?.profilePicturePath}
                    className="chatprof"
                  />
                </div>
                <div className="mt-3">
                  {/* <h5 className='themeclr'>Dummy Resort</h5> */}
                  <p className="text-muted f-14 my-2">
                    {singledispute?.createdAt?.slice(0, 10)}
                    <span className="themeclr" />
                  </p>
                  {/* <p className='text-muted f-14'>1 Guest . $765 . <span className='themeclr'>Canceled</span></p>  */}
                </div>
              </div>
              <div className="shadowbox p-4 mt-3">
                <div className="attachimg">
                  {singledispute?.status != "closed" && (
                    <>
                      <div className="d-sm-flex gap-2 align-items-center mb-3">
                        <h6 className="themeclr mb-0">Attachments</h6>
                        <div className="choosefiles">
                          <input
                            type="file"
                            multiple
                            className="btn btn-theme"
                            onChange={(e) => {
                              var image = e?.target?.files[0];
                              var fileName = image.name;
                              var idxDot = fileName.lastIndexOf(".") + 1;
                              var extFile = fileName
                                .substr(idxDot, fileName.length)
                                .toLowerCase();
                              if (
                                extFile == "jpg" ||
                                extFile == "jpeg" ||
                                extFile == "png" ||
                                extFile == "webp" ||
                                extFile == "svg"
                              ) {
                                setImage(e?.target?.files[0]);
                                SetImageshow(
                                  URL.createObjectURL(e?.target?.files[0])
                                );
                              } else {
                                toast.error("Invalid file format!");
                              }
                            }}
                          />
                          <button className="btn btn-theme">Choose</button>
                        </div>
                      </div>

                      <ul className="mb-0 ps-0">
                        {/* <i className='fa fa-times'></i> */}
                        <li>
                          jpeg <button className="btn" />
                        </li>
                        <li>
                          png <button className="btn" />
                        </li>
                        <li>
                          webp <button className="btn" />
                        </li>

                        <li>
                          jpg <button className="btn" />
                        </li>
                      </ul>

                      <div className="d-flex gap-2 jc-between">
                        {imageshow && <img src={imageshow} />}
                        {/* <img src={img1} />
                                        <img src={img1} /> */}
                      </div>
                      <button
                        className="btn btn-theme mt-3"
                        onClick={async () => {
                          await closedisputehook(singledispute?.ticketid).then(
                            () => {
                              window.location.reload();
                            }
                          );
                        }}
                      >
                        Close dispute
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dispute;
