import React, { useEffect, useState } from "react";
import Footer from "../../../../Common/Footer";
import HostHeader from "../../HostHeader";
import prof from "../../../../../Assets/images/prof.jpg";
import DataTable from "react-data-table-component";
import CancelBook from "./Modal/CancelBook";
import { Tabs, Tab, Button } from "react-bootstrap";

import {
  checkBookingStatus,
  getUserDatas,
  getUserEmail,
  getwithdrawhistoryhooks,
} from "../../../../../Hooks/useUserHook";
import {
  getUserBookings,
  getUserCancelledTrips,
  getUserTrips,
} from "../../../../../Hooks/useUserHostingHook";
import { API_URL } from "../../../../../config/env";
// import { getUserDatas } from '../../../../../Hooks/useUserHook';
// import { getUserBookings } from '../../../../../Hooks/useUserHostingHook';
import GiveReview from "../../../../Common/Modals/GiveReivew";
import { useNavigate } from "react-router-dom";
import { GrNext, GrPrevious } from "react-icons/gr";
import HeaderDash from "../../../../Common/HeaderDash";

const WithdrawHistory = () => {
  const navigate = useNavigate();
  const [withdraw, setWithdraw] = useState([]);

  useEffect(() => {
    async function fetchdata() {
      if (getUserEmail()) {
        let data = { email: await getUserEmail() };
        console.log("result in withdraw history payload", data);
        let result = await getwithdrawhistoryhooks(data);
        console.log("result in withdraw history", result?.data?.record);
        setWithdraw(result?.data?.record?.reverse());
      }
    }
    fetchdata();
  },[]);

  const data = [
    {
      id: "#101",
      date: "12/04/2022",
      email: "lorem@gmail.com",
      amount: "$200",
      status: "Completed",
    },
    {
      id: "#102",
      date: "12/04/2022",
      email: "lorem@gmail.com",
      amount: "$200",
      status: "Completed",
    },
  ];

  const columns = [
    {
      name: "Id",
      selector: (row) => row._id,
    },
    {
      name: "Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
    },
    {
      name: "Email",
      selector: (row) => row.hostemail,
    },

    {
      name: "Amount",
      selector: (row) => row.requestamount,
    },
    {
      name: "Status",
      selector: (row) => row.paymentstatus,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
  ];

  const MblData = [
    {
      id: "u42i52429492958929ufshj",
      date: "12/12/20223",
      email: "email@flutterpads.com",
      amount: "$585",
    },
  ];

  return (
    <div className="trips">
      <HeaderDash />
      <HostHeader />
      <div className="container container_custom mh-100vh-header">
        <div className="cmn-table-webView mt-4 mt-lg-5">
          <div className="py-3 px-0">
            <div className="d-sm-flex jc-between align-items-center">
              <h4 className="mb-sm-0 mb-3 themeclr1 fw-500 p-3">
                Withdraw History
              </h4>
            </div>
          </div>

          <div className="disputetab"> 
            <div className="position-relative no-data-bdr-top">
              <DataTable
                className="tripstay_tab1 cmn-data-table"
                pagination
                columns={columns}
                data={withdraw}
              />
              <div className="showing-records">Showing 3 of 3 records</div>
            </div>
          </div>
        </div>
        <div className="cmn-table-mblView mt-4 mb-4">
          <h2 className="table-heading mb-4 fw-600">Withdrawal History</h2>
          <div className="datas">
            <div className="row">
              {MblData.map((item, index) => {
                return (
                  <div className="col-12">
                    <div className="table-datas">
                      <div className="mb-2">
                        <span className="name">ID: </span>
                        <span className="value">{item.id}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Date: </span>
                        <span className="value">{item.date}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Email :</span>
                        <span className="value">{item.email}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Amount: </span>
                        <span className="value">{item.amount}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Status: </span>
                        <Button className="view-details cus-btn-link-black">
                          Pending
                        </Button>
                      </div>
                      <div className="mb-2">
                        <span className="name">Status: </span>
                        <Button className="view-details cus-btn-link-black">
                          Raised
                        </Button>
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
            <div className="prev-next-btn">
              <Button className="">
                <GrPrevious />
              </Button>
              <span className="f-15 fw-500">Showing 1 of 1 records</span>
              <Button className="">
                <GrNext />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WithdrawHistory;
