import React, { useEffect, useState } from "react";

import Header from "../Common/Header";
import Footer from "../Common/Footer";

import { Link, useNavigate } from "react-router-dom";
import userIcon from "../../Assets/images/account-user-cion.png";
import basicplan from "../../Assets/images/basicplan.png";
import premiumplan from "../../Assets/images/premiumplan.png";
import ultraplan from "../../Assets/images/ultraplan.png";
import moment from "moment";
import HostSubscribeModal from "./HostSubscribeModal";
import {
  Gethostusersubscrptionhooks,
  getUserDatas,
  getUserEmail,
  getusersubscriptiondetailshooks,
} from "../../Hooks/useUserHook";
import { API_URL } from "../../config/env";
import NoSubscription from "./NoSubscription";


  
const SubscriptionsList = (props) => {

  const [hostsub , setHostsub] = useState({});
  const [guestsub , setGuestsub] = useState({});
  const [userdata , setUserdata] = useState({});


  useEffect(() => {
    fetchdata();
  } , [])





  const fetchdata = async() => {
    let result = await getusersubscriptiondetailshooks({email : getUserEmail()});
    if(result?.data?.type == "Success"){
      setHostsub(result?.data?.hostsubdetail);
      setGuestsub(result?.data?.guestsubdetail);
      setUserdata(result?.data?.user);
    }
  }


  return (
    <div id="hostaccount" className="nocenterhead account-details">
      <Header />
      <div className="container container_custom pt-lg-5 pb-lg-5 ">
        <h3 className="account fw-700 mb-0">Subscriptions List</h3>
        <div>
          <div className="plansrow_sub row mt-4 mt-xxl-5 mb-4 mb-lg-5">
            {hostsub?.hostsubdata && 
            <div className="col-12 col-md-6 col-xl-4 d-flex justify-content-center align-items-center position-relative">
              <button className="expired_badge">Expired</button>
              <div className="acc-box accox_sm d-flex flex-column p-4 align-items-center justify-content-center">
                <h2 className="subs_list_guest_host_txt">Host</h2>
                <img
                  src={basicplan}
                  alt="user-icon"
                  className="user-icon-img "
                />
                <h6 className="pt-2 font_pers mt-3">{hostsub?.hostsubdata?.name}</h6>
                <p className="pt-2  text-center price_txt_new_sub">$70</p>
                <p className="pt-2 mb-0 text-center no-of_subs-days">
                  Plan days : {hostsub?.hostsubdata?.days} days
                </p>
                <p className=" pt-1 mb-0 text-center no-of_subs-days">
                  Start date : {new Date(parseInt(userdata?.hostsubexpiry) - (parseInt(hostsub?.hostsubdata?.days) * 60 * 60 * 24 * 1000))?.toLocaleDateString()}
                </p>
                <p className=" pt-1 mb-0 text-center no-of_subs-days">
                  Expired date : {/* Expired Subscriptions */}
                  {new Date(parseInt(userdata?.hostsubexpiry))?.toLocaleDateString()}
                </p>

                {/* <p className=" pt-1 mb-0 text-center no-of_subs-days">
                  Expired !!!
                </p> */}
              </div>
            </div>}



            {guestsub?.guestsubdata && 
            <div className="col-12 col-md-6 col-xl-4 d-flex justify-content-center align-items-center position-relative">
              <button className="expired_badge">Expired</button>
              <div className="acc-box accox_sm d-flex flex-column p-4 align-items-center justify-content-center">
                <h2 className="subs_list_guest_host_txt">Guest</h2>
                <img
                  src={basicplan}
                  alt="user-icon"
                  className="user-icon-img "
                />
                <h6 className="pt-2 font_pers mt-3">{guestsub?.guestsubdata?.name}</h6>
                <p className="pt-2  text-center price_txt_new_sub">$70</p>
                <p className="pt-2 mb-0 text-center no-of_subs-days">
                  Plan days : {guestsub?.guestsubdata?.days} days
                </p>
                <p className=" pt-1 mb-0 text-center no-of_subs-days">
                  Start date : {new Date(parseInt(userdata?.guestsubexpiry) - (parseInt(guestsub?.guestsubdata?.days) * 60 * 60 * 24 * 1000))?.toLocaleDateString()}
                </p>
                <p className=" pt-1 mb-0 text-center no-of_subs-days">
                  Expired date : {/* Expired Subscriptions */}
                  {new Date(parseInt(userdata?.guestsubexpiry))?.toLocaleDateString()}
                </p>

                {/* <p className=" pt-1 mb-0 text-center no-of_subs-days">
                  Expired !!!
                </p> */}
              </div>
            </div>}
          </div>
          <div className="text-center">
            {/* <h4 className="need_to_subs">Need Host Subscription </h4>

            <p className="need-to-deactive mb-2">Need to subscribe?</p> */}

            {(!hostsub && !guestsub) && <div>
            <NoSubscription />
            <div className="subscribe_block">
              <p className="take-care-of mt-3">
                <Link to={"/hostsubscribe"} className="btn btn_theme_black">
                  Subscribe for Host
                </Link>
              </p>
              <p className="my-1 fw-500"></p>
              <p className="take-care-of">
                <Link to={"/guestsubscribe"} className="btn btn_theme_black">
                  Subscribe for Guest
                </Link>
              </p>
            </div>
            </div>}

            <div className="subscribe_block">
              {userdata?.hostsubexpiry && Date.now() > parseInt(userdata?.hostsubexpiry) && <p className="take-care-of mt-3">
                <Link to={"/hostsubscribe"} className="btn btn_theme_black">
                  Subscribe for Host
                </Link>
              </p>}
              <p className="my-1 fw-500"></p>
              {userdata?.guestsubexpiry && Date.now() > parseInt(userdata?.guestsubexpiry) && <p className="take-care-of">
                <Link to={"/guestsubscribe"} className="btn btn_theme_black">
                  Subscribe for Guest
                </Link>
              </p>}

             {userdata?.hostsubexpiry && Date.now() < parseInt(userdata?.hostsubexpiry) &&  <p className="take-care-of mb-3 mt-3">
                Host subscription expired in {parseInt((parseInt(userdata?.hostsubexpiry) - Date.now()) / 86400000)} days
              </p>}

              {userdata?.guestsubexpiry && Date.now() < parseInt(userdata?.guestsubexpiry) && <p className="take-care-of mb-3 mt-3">
                Guest subscription expired in {parseInt((parseInt(userdata?.guestsubexpiry) - Date.now()) / 86400000)} days
              </p>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SubscriptionsList;
