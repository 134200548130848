import React, { Component, useState } from 'react';
import {Modal, Button, Form} from 'react-bootstrap'
import CommitModal from './CommitModal';
import * as moment from "moment"

import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { updateUserData } from '../../../Hooks/useUserHook';

const FinishSign = (props) => {


    const MAX_AGE = 100;
    const MIN_AGE = 18;
    
    const getMinAndMaxDateForDob = () => {
        const currenTime = new Date();
        const currentYear = currenTime.getFullYear();
        const currentMonth = currenTime.getMonth();
        const minYear = currentYear - MAX_AGE;
        const maxYear = currentYear - MIN_AGE;
        const minDate = new Date(`${minYear}-${currentMonth}-01`);
        const maxDate = new Date(`${maxYear}-${currentMonth}-01`);
        return {minDate, maxDate};
     }
    const [showfinishsign , setShowFinishSign] = useState(true)
    const [showcommit , setShowCommit] = useState(false)
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateofbirth, setDateofbirth] = useState(getMinAndMaxDateForDob().maxDate);
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [dobErr, setDobErr] = useState(false);
    const [ageErr, setAgeErr] = useState(false);

   
  

//    const modals = () =>{
//     updateUserDataFunction();
//     setShowCommit(true)
//     setShowFinishSign(false)
//    }

   const handleChange = (e) => {
    console.log(e.target);
        if(e.target.id == "firstname"){
            setFirstName(e.target.value);
            setFirstNameErr(false);
        }else if(e.target.id == "lastname"){
            setLastName(e.target.value);
            setLastNameErr(false);
        }else if(e.target.id == "dateofbirth"){
            setDateofbirth(e.target.value);
            setDobErr(false);
            setAgeErr(false);
        }
   }

   const updateUserDataFunction = async () => {
    const currentYr = new Date().getFullYear();
    const dobYr = new Date(dateofbirth).getFullYear();
    console.log("currentYr",currentYr,"dateofBirth",dobYr,"currentYr-dobYr",currentYr-dobYr,"currentYr-dateofBirth<18",(currentYr-dobYr)<18)
        if((currentYr-dobYr)<18){
            setAgeErr(true);
        }
        const data = {
            email:props.email,
            firstName:firstName,
            lastName:lastName,
            dob:dateofbirth
        }
        console.log("firstname",firstName,"lastname",lastName,"dob",dateofbirth);
        if(firstName != "" && lastName != "" && dateofbirth != "" && ageErr != true){
            await updateUserData(data).then((value)=>{
                console.log("returned value from updateuserdata",value);
                setShowCommit(true)
                setShowFinishSign(false)
            })
            console.log("success");
        }else{
            if(firstName == ""){
                setFirstNameErr(true);
            }if(lastName == ""){
                setLastNameErr(true);
            }if(dateofbirth == ""){ 
                setDobErr(true);
            }
        }
   }


    return (
     <div className='finishsign'>
        {showcommit && <CommitModal onDismiss={() =>setShowCommit(false) } />}
         <Modal show={showfinishsign} centered scrollable className='modal_style'>
        <Modal.Header className='border-0 pb-0'>

            <button type="button" className="btn close close_modal" onClick={() =>{setShowFinishSign(false)}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

        </Modal.Header>
        <Modal.Body>
            <h6 className='mb-3 text-center'>Finish SignUp</h6>
            <div className='finishform'>
           <Form>
            <div className='doubleinput'>
                <Form.Group className="inputs top" >
                    <Form.Label>FirstName</Form.Label>
                    <Form.Control type="text" placeholder="Enter First Name" id="firstname" onChange={(e)=>{handleChange(e)}}/>
                    {(firstNameErr)?<Form.Text className=" text-danger">
                    First Name is a mandatory field.
                    </Form.Text>:<></>}
                </Form.Group>
                <Form.Group className="mb-3 inputs bot" >
                    <Form.Label>LastName</Form.Label>
                    <Form.Control type="text" placeholder="Enter Last Name" id="lastname" onChange={(e)=>{handleChange(e)}}/>
                    {(lastNameErr)?<Form.Text className=" text-danger">
                    Last Name is a mandatory field.
                    </Form.Text>:<></>}

                </Form.Group>
            </div>
           
            <p className='f-12'>Make sure it matches the name on your government ID</p>

            <h5 className='mt-3 date_sign_fon'>Date of Birth</h5>
         
            <Form.Group className="mb-3 mt-3 pt-2 inputs bot inut_date_sign" >
                {/* <Form.Label className='mt-3'>Date of Birth</Form.Label> */}

                                <Datepicker className='datepicker_theme_flutter'
                                    //   minDate={new Date()} 
                                    maxDate={getMinAndMaxDateForDob().maxDate}
                                    onKeyDown={(e) => {
                                        e.preventDefault()
                                    }}
                                    selected={dateofbirth}
                                    onChange={(e) => {
                                        setDateofbirth(e);
                                    }}
                                />
                {/* <Form.Control type="date" placeholder="Enter Date of Birth" id="dateofbirth" max={new Date(getMinAndMaxDateForDob().maxDate).toLocaleDateString('en-CA')} 
                onkeyDown={false}
                onChange={(e)=>{handleChange(e)}}/> */}
                {(dobErr)?<Form.Text className="text-danger">
                Date of birth is a mandatory field.
                    </Form.Text>:(ageErr)?<Form.Text className="text-danger">
                {/* You must be 18 or older to use this platform. */}
                    </Form.Text>:<></>}
            </Form.Group>
            <p className='f-12'> We'll email you trip confirmation and receipts</p>
            {/* <p className='text-center text-muted'>This info came from Google</p> */}
        
            <p className='f-12'>By selecting <b>agree and continue</b>, I agree Share Resort <a href="#" target="_blank" className='orsnge_y'>Term of Service</a> ,   
             <a href="#" target="_blank" className='orsnge_y'>Payment Terms of Service</a> and <a href="#" target="_blank" className='orsnge_y'>NonDisclimers Policy</a> 
            acknowledge theme<a href="#" target="_blank" className='orsnge_y'>Privacy Policy</a></p>


            <Button className='btn btn-theme w-full my-4' variant="outline-dark" onClick={() => updateUserDataFunction()}>Agree and Continue</Button>

 <hr/>
            <p className='f-12'>FlutterPads will send you members-only deals, inspiration, marketing emails, and push notifications. You can opt out of receiving these at any time in your account 
                settings or directly from the marketing notification.</p>
                <div className='form_chekc_nw'>
                <Form.Check  type="checkbox" label="I don’t want to receive marketing messages from FlutterPads."
          />
          </div>
           </Form>

            </div>
        </Modal.Body>
         </Modal>
     </div>
    )
}

export default FinishSign