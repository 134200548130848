import React from "react";
import HostHeader from "../BecomeHost/AfterHost/HostHeader";
import Footer from "./Footer";

// Images imports

import contactBanner from "../../Assets/images/contact/R.png";
import { useState, useEffect } from "react";
import { addcontactushooks, getallcmsdatahook } from "../../Hooks/useUserHook";
import { API_URL } from "../../config/env";
import Header from "./Header";
import { toast } from "react-hot-toast";
import Captchaa from "./Recaptcha";
import PhoneInput from "react-phone-input-2";
const Contactus = () => {
  const [cms, setCms] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [phonecode, setPhonecode] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("Afghanistan(+93)");
  console.log("recaptchadata", recaptcha);
  useEffect(() => {
    async function fetchdata() {
      console.log("");
      await getallcmsdatahook().then((result) => {
        console.log("get all crm hook result", result?.data?.data);
        result?.data?.data?.map((data, i) => (data.sno = i + 1));
        setCms(result?.data?.data);
      });
    }
    fetchdata();
  }, []);
  const cmsFilter = (data) => {
    try {
      let filterdata = cms?.find(
        (el) => el.identifier == data && el?.status == "active"
      );
      console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
      return filterdata;
    } catch (e) {
      console.log("cmsFilter_err", e);
    }
  };

  const handlePhoneNumber = (value, country) => {
    console.log("phoneeeeeeeee", value, country);
    const { dialCode } = country;
    let newPhoneNo = value;
    console.log(
      "handlpeph",
      country?.name,
      country,
      country?.dialCode,
      `${country?.name}(+${country?.dialCode})`
    );
    if (dialCode) {
      setPhonecode(dialCode);
      setCountryCode(`${country?.name}(+${country?.dialCode})`);
      setPhoneNumber(newPhoneNo.slice(dialCode.length));
    } else if (value) {
      setPhoneNumber(value);
    }
  };

  const emailValidation = () => {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    const validated = emailRegex.test(email);
    console.log("validated datas", validated);
    return validated;
  };

  const handlesubmit = async () => {
    console.log("phonenumbetdarta", phoneNumber, phonecode);
    if (
      firstname &&
      lastname &&
      email &&
      message &&
      // recaptcha &&
      emailValidation() &&
      phoneNumber
    ) {
      let payload = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        message: message,
        // recaptcha: recaptcha,
        phoneno: phoneNumber,
        phonecode: phonecode,
      };

      let result = await addcontactushooks(payload);
      console.log("result datsgal", result, result?.type);
      if (result?.type == "Success") {
        toast.success("Submitted Successfully!");
        setFirstname("");
        setLastname("");
        setMessage("");
        setEmail("");
        setRecaptcha("");
        window.location.reload();
      }
    }

    if (!emailValidation()) {
      toast.error("Invalid Email Address!");
    }
  };

  return cms && cms != 0 ? (
    <>
      {/* <HostHeader /> */}
      <Header />
      {/* Contact-us */}

      {/* <section className="pad_y">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              {cmsFilter("contactbanner_01")?.image && <div>

                <img src={cmsFilter("contactbanner_01")?.path ? API_URL + cmsFilter("contactbanner_01")?.path.split('/')[1] + "/" + cmsFilter("contactbanner_01")?.path?.split('/')[2] + "/" + cmsFilter("contactbanner_01")?.image : <></>} alt="contact banner" className="img-fluid" />
              </div>}
              <img
                src={contactBanner}
                alt="contact banner"
                className="img-fluid"
              />
            </div>
            <div className="col-12 col-md-6 mt-4 mt-md-0 px-md-4 px-lg-5 mt-md-0">
              <div className="d-flex flex-column align-item-start">
                <h2>
                  {cmsFilter("contactbanner_01")?.name != undefined
                    ? cmsFilter("contactbanner_01")?.name
                    : ""}
                  How can we help?
                </h2>
                <p
                  className="mt-3 cont_subtle"
                  dangerouslySetInnerHTML={{
                    __html:
                      cmsFilter("contactbanner_01")?.content != undefined
                        ? cmsFilter("contactbanner_01")?.content
                        : "",
                  }}
                >
                  Our team can answer questions before you move in. Already
                  living with Landing? We're here to help during every part of
                  your reservation.
                </p>
                <p className="mt-4 d-flex align-items-center">
                  {cmsFilter("contactbanner_01")?.phone != undefined ? (
                    <i class="fa-solid fa-phone ph_icon_clr" />
                  ) : (
                    <></>
                  )}{" "}
                  <a href="/" className="ms-3 ph_txt">
                    {cmsFilter("contactbanner_01")?.phone != undefined
                      ? cmsFilter("contactbanner_01")?.phone
                      : ""}
                  </a>
                  <i class="fa-solid fa-phone ph_icon_clr" />
                  <a href="/" className="ms-3 ph_txt">
                    {" "}
                    415-231-1701
                  </a>
                </p>
                <div className="mt-3 d-flex align-items-start">
                  {cmsFilter("contactbanner_01")?.address != undefined ? (
                    <i class="fa-solid fa-location-dot ph_icon_clr" />
                  ) : (
                    <></>
                  )}
                  <i class="fa-solid fa-location-dot ph_icon_clr" />

                  <p className="ms-3 strt_txt">
                    {cmsFilter("contactbanner_01")?.address != undefined
                      ? cmsFilter("contactbanner_01")?.address
                      : ""}
                  </p>
                  <p className="ms-3 strt_txt">
                    17 20th Street North, Suite 100 Birmingham, AL 35203
                  </p>
                </div>
              </div>
              <div className="contact_details">
                <h2 className="text-center">Contact Us</h2>
                <div className="row">
                  <div className="col-12 mb-4">
                    <label for="firstname">First Name</label>
                    <input
                      type="text"
                      id="firstname"
                      className="form-control mb-2 mt-1 input_big_ne"
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label for="lastname">Last Name</label>
                    <input
                      type="text"
                      id="lastname"
                      className="form-control mb-2 mt-1 input_big_ne"
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      className="form-control mb-2 mt-1 input_big_ne"
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <label for="message">Message</label>
                    <textarea
                      id="message"
                      className="form-control mb-2 mt-1 input_big_ne"
                      rows={5}
                    />
                  </div>
                  <div className="col-12 mb-5 text-end">
                    <button className="btn btn-theme">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className="contact_details container mt-4">
        <h3 className="text-center mb-4 pb-1 fw-600">Contact Us</h3>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-md-4 mb-1">
            <label for="firstname">First Name</label>
            <input
              type="text"
              id="firstname"
              value={firstname}
              onChange={(e) => {
                setFirstname(e?.target?.value);
              }}
              className="form-control mb-2 mt-1 input_big_ne"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-md-4 mb-1">
            <label for="lastname">Last Name</label>
            <input
              type="text"
              id="lastname"
              value={lastname}
              className="form-control mb-2 mt-1 input_big_ne"
              onChange={(e) => {
                setLastname(e?.target?.value);
              }}
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-md-4 mb-1">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e?.target?.value);
              }}
              className="form-control mb-2 mt-1 input_big_ne"
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-md-4 mb-1">
            <label for="firstname">Phone Number</label>
            <PhoneInput
              country={"us"}
              value={phonecode + phoneNumber}
              onChange={handlePhoneNumber}
              className="custom_phone_inp"
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-md-4 mb-1">
            <label for="message">Message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => {
                setMessage(e?.target?.value);
              }}
              className="form-control mb-2 mt-1 input_big_ne"
              rows={4}
            />
          </div>
          {/* <div className="col-12 mb-4">
            <Captchaa
              onchange={(e) => {
                console.log(e, "Captchaaa");
                setRecaptcha(e);
              }}
            />
          </div> */}
          <div className="col-12 mb-5 text-end">
            <button
              className="btn btn-theme"
              onClick={handlesubmit}
              disabled={
                !firstname ||
                !lastname ||
                !email ||
                !message ||
                // !recaptcha ||
                !phoneNumber
              }
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <></>
  );
};

export default Contactus;
