import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL } from '../config/env';

import { cancelRoomApi, createHostingApi, getArrivingSoonApi, getBookingDetailsApi, getCheckoutTodayApi, getOverAllRatingApi, getPendingBookingsApi, getReverseGeolocationApi, getRoomRatingApi, getUpcomingBookingsApi, getUserBookingsApi, getUserHostingsApi, getUserPendingApi, getUserRoomListingsApi, getUserTripsApi, hostRoomApprovalApi, listSettingsApi, setRoomPriceApi, getUserCancelledApi, getCancellationAmountApi, getCancellationDetailsApi, getOverAllRatingExperience, getUserDetailsApi, cancelbookingApi, cancellationtypeApi, getUserSplitReserveApi, getSplitBookingDetailsApi, updatehostingdataApi, getsinglehostingdataApi, updateuserdetailsApi } from '../config/userRouteFront';
import { getHostDetailsApi, getHostedDatasApi, getSingleHostingDetailApi, getAdminDetailApi, getSingleExperienceDetailsApi, getallhostdataApi } from '../config/adminRouteFront';

// const CC = require('currency-converter-lt')
const cookies = new Cookies();

const headers = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': cookies.get('accessToken') ? `Bearer ${cookies.get('accessToken')}` : (localStorage.getItem("USRDT") ? `Bearer ${JSON.parse(atob( localStorage.getItem("USRDT"))).token}` : "")
        // 'Authorization': `Bearer ${cookies.get('accessToken')}`
    }
}
// export const uploadHostImages = async (ImageFiles,email) => {
//     try{
//     console.log("imageFile>>>>>",ImageFiles);
//     let imageArray = [];
//     const bodyformData = new FormData()
//     if(ImageFiles.length>0){
//     for (var i = 0; i < ImageFiles.length; i++) {
//         bodyformData.append(`Files${i}`, ImageFiles[i])
//     }
//     bodyformData.append('email',email);
//     await axios({
//         'method': 'post',
//         'url': API_URL+createHostingPhotosApi,
//         'headers': {
//             'Content-Type': 'multipart/form-data'
//         },
//         'data': bodyformData,

//     }).then((data)=>{console.log('data from uploadHostImages:',data);})

//     }
// }
// catch(e){
//     console.log("errorrrr",e);
// }
// }

export const createHostUser = async (hostDatas) => {
    const bodyformData = new FormData();
    console.log("host datas createHostUser", hostDatas,  hostDatas.photos,
    hostDatas.photos.length);
    for (var i = 0; i < Object.values(hostDatas.photos).length; i++) {
        console.log("hostDatas.photos[i]", hostDatas.photos[i])
        bodyformData.append(`Files${i}`, hostDatas.photos[i]);
    }
    bodyformData.append(`Files${hostDatas?.photos?.length}` , hostDatas?.video);
    // if(hostDatas?.video){
    //     bodyformData.append("video" , "yes");
    // }
    // else{
        bodyformData.append("video" , "no");
    // }
    bodyformData.append('email', hostDatas.email);
    bodyformData.append('title', hostDatas.title);
    bodyformData.append('propertyTypeGroup', JSON.stringify(hostDatas.propertyTypeGroup));
    bodyformData.append('propertyType', JSON.stringify(hostDatas.propertyType));
    bodyformData.append('address', JSON.stringify(hostDatas.address));
    bodyformData.append('amenities', JSON.stringify(hostDatas.amenities));
    bodyformData.append('privacyType', JSON.stringify(hostDatas.privacyType));
    bodyformData.append('description', JSON.stringify(hostDatas.description));
    bodyformData.append('descriptionContent', hostDatas.descriptionContent);
    bodyformData.append('price', hostDatas.price);
    // bodyformData.append('legal', JSON.stringify(hostDatas.legal));
    bodyformData.append('legal',hostDatas.legal)
    bodyformData.append('firstName', hostDatas.firstName);
    bodyformData.append('lastName', hostDatas.lastName);
    bodyformData.append('cancellationPolicy', JSON.stringify(hostDatas.cancellationPolicy));
    bodyformData.append('instantBooking', hostDatas.instantBooking);
    bodyformData.append('floorPlan', JSON.stringify(hostDatas.floorPlan));
    bodyformData.append('category', JSON.stringify(hostDatas?.category));
    bodyformData.append('checkintime', hostDatas?.checkintime);
    bodyformData.append('checkouttime', hostDatas?.checkouttime);
    bodyformData.append('rules' , hostDatas?.rules);
    console.log("host datas inside hook:", hostDatas, "bodyformdata :", bodyformData);
    for (const value of bodyformData.values()) {
        console.log("values of formdata inside useUserHostingHook:", value);
    }
    await axios({
        'method': 'post',
        'url': API_URL + createHostingApi,
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': cookies.get('accessToken') ? `Bearer ${cookies.get('accessToken')}` : (localStorage.getItem("USRDT") ? `Bearer ${JSON.parse(atob( localStorage.getItem("USRDT"))).token}` : "")
        },
        'data': bodyformData,

    })
        .then((data) => {
            // localStorage.clear();
        })
}

export const getCurrentLocationFromNav = async (lat, long) => {
    let dataToReturn;
    const data = {
        latitude: lat,
        longitude: long
    }
    // await axios.post(API_URL+getReverseGeolocationApi,data).then((data)=>{
    //     dataToReturn = data;
    // })

    await axios.get(`http://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${long}&exclude=hourly,daily&appid=${"c5410198dae06dddc405cf7f50e09f8c"}`).then((data) => {
        dataToReturn = data;
    })

    return dataToReturn;
}



export const getHostingDetails = async () => {
    let dataToReturn = []
    await axios.get(API_URL + getHostDetailsApi).then((data) => {
        if (data.data.status) {
            dataToReturn = data.data.data
        }
    })
    return dataToReturn
}

export const getHostedDatas = async (start, end , currency) => {
    let data = {
        starting: start,
        ending: end,
        currency : currency
    }
    let dataToReturn = [];
    await axios.post(API_URL + getHostedDatasApi, data).then((data) => {
        dataToReturn = data.data;
    });
    if(currency == "USD"){
        return dataToReturn;
    }
    else{
        // await dataToReturn?.map((a , i) => {
        //     let currencyConverter = new CC({from:"USD", to:currency, amount:parseFloat(a.price), isDecimalComma:true})
        //     currencyConverter.convert().then((response) => {
        //       console.log("currencyConverter" , response) //or do something else
        //       a.price = response
        //   })
        //   })
        return dataToReturn;
    }
}

export const getRoomUsingId = async (id) => {
    let dataToReturn = {};
    await axios.get(API_URL + getSingleHostingDetailApi + `/${id}`).then((data) => {
        dataToReturn = data.data;
    })
    return dataToReturn;
}

export const getAdminDetails = async () => {
    let dataToReturn = {}
    await axios.get(API_URL + getAdminDetailApi).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}


export const getPendingBookings = async (mail) => {
    let dataToReturn = {}
    await axios.get(API_URL + getPendingBookingsApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getArrivingSoon = async (mail) => {
    let dataToReturn = {}
    await axios.get(API_URL + getArrivingSoonApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getcancelletbooking = async (mail) => {
    let dataToReturn = {}
    await axios.get(API_URL + cancelbookingApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getCancellationtypehooks = async (type) => {
    let dataToReturn = {}
    await axios.get(API_URL + cancellationtypeApi + "/" + type).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getCheckoutToday = async (mail) => {
    let dataToReturn = {}
    await axios.get(API_URL + getCheckoutTodayApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getUpcomingBookings = async (mail) => {
    let dataToReturn = {}
    await axios.get(API_URL + getUpcomingBookingsApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const hostRoomApproval = async (data) => {
    let dataToReturn = {}
    await axios.post(API_URL + hostRoomApprovalApi, data, headers).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getBookingDetails = async (id) => {
    let dataToReturn = {}
    await axios.get(API_URL + getBookingDetailsApi + "/" + id).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getUserHostings = async (mail) => {
    let dataToReturn = {}
    await axios.get(API_URL + getUserHostingsApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getUserDetails = async (mail) => {
    console.log('maildetails',mail)
    let dataToReturn = {}
    await axios.get(API_URL + getUserDetailsApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}


export const getUserBookings = async (mail) => {
    console.log('mail', mail)
    let dataToReturn = {}
    await axios.get(API_URL + getUserBookingsApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getUserPendings = async (mail) => {
    console.log('mail1', mail)
    let dataToReturn = {}
    await axios.get(API_URL + getUserPendingApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getUserTrips = async (mail) => {
    console.log('mail2', mail)
    let dataToReturn = {}
    await axios.get(API_URL + getUserTripsApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const getUserCancelledTrips = async (mail) => {
    console.log('emaildata', mail)
    let dataToReturn = {}
    await axios.get(API_URL + getUserCancelledApi + "/" + mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const setRoomPricePerDay = async (data) => {
    console.log('datasssssssssssssssasdfasd',data,new Date(data.pricePerDay[0].Date),data.pricePerDay[0].Date)
    console.log('ne dateofg',new Date(data.pricePerDay[0].Date))
    let dataToReturn;
    const bodyformData = new FormData()
    bodyformData.append("isBlocked",data.isBlocked);
    bodyformData.append("roomId",data.roomId);
    bodyformData.append("pricePerDay",JSON.stringify(data.pricePerDay))
    bodyformData.append("startDate",data.pricePerDay[0].Date)
    bodyformData.append("endDate",data.pricePerDay[data.pricePerDay.length-1].Date)
    bodyformData.append("price",data.pricePerDay[0].price)
    bodyformData.append("id" , data?.id);
    bodyformData.append("finaldate" , JSON.stringify(data?.finaldate));
    bodyformData.append("start" , new Date(data?.start)?.getTime());
    bodyformData.append("end" , new Date(data?.end).getTime());
    if(data?.reference){
        bodyformData.append("reference" , data?.reference);
    }

    // await axios.post(API_URL + setRoomPriceApi, bodyformData).then((val) => {
    //     console.log("setRoomaPricePerDay:", val);
    //     dataToReturn = val.data;
    // })
    await axios.post(API_URL + setRoomPriceApi, bodyformData).then((val) => {
        console.log("setRoomaPricePerDay:", val);
        dataToReturn = val.data;
    })
    return dataToReturn;
}

export const getOverAllRating = async (roomId) => {
    let dataToReturn = {};
    await axios.get(API_URL + getOverAllRatingApi + "/" + roomId).then((data) => {
        dataToReturn = data.data;
    });
    return dataToReturn;
}

export const getRoomRating = async (roomId) => {
    let dataToReturn = {};
    await axios.get(API_URL + getRoomRatingApi + "/" + roomId).then((data) => {
        dataToReturn = data.data;
    });
    return dataToReturn;
}

export const getUserRoomListings = async (email) => {
    let dataToReturn;
    await axios.get(API_URL + getUserRoomListingsApi + "/" + email).then((data) => {
        dataToReturn = data.data;
    });
    return dataToReturn;
}

export const listSetting = async (id, type, settings) => {
    const data = {
        type: type,
        listSetting: settings,
        roomId: id
    }
    let dataToReturn;
    await axios.post(API_URL + listSettingsApi, data , headers).then((val) => {
        dataToReturn = val.data;
    })
    return dataToReturn;

}

export const cancelRoom = async (data) => {
    console.log('datacancelRoom', data)
    let dataToReturn;
    await axios.post(API_URL + cancelRoomApi, data).then((val) => {
        dataToReturn = val.data;
    })
    return dataToReturn;
}


export const getCancellationAmount = async (data) => {
    console.log('datagetCancellationAmount', data)
    let dataToReturn;
    await axios.post(API_URL + getCancellationAmountApi, data).then((val) => {
        dataToReturn = val.data;
    })
    return dataToReturn;
}

export const getSingleExperienceDetails = async (data) => {
    console.log('check1', data);
    let dataToReturn;
    await axios.get(API_URL + getSingleExperienceDetailsApi + "/" + data).then((val) => {

        dataToReturn = val.data;
    })
    return dataToReturn;
}


export const getCancellationDetails = async (id) => {
    let dataToReturn;
    await axios.get(API_URL + getCancellationDetailsApi + "/" + id).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn
}

export const overAllReviewExperience = async (id) => {
    console.log('over all id:', id)
    let dataToReturn;
    await axios.get(API_URL + getOverAllRatingExperience + "/" + id).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn
}


export const getUserSplitReserveBookings = async (mail) => {
    console.log('mail', mail)
    let dataToReturn = {}
    await axios.get(API_URL + getUserSplitReserveApi + "/" +mail).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}


export const getSplitBookingDetails = async (id) => {
    let dataToReturn = {}
    await axios.get(API_URL + getSplitBookingDetailsApi + "/" + id).then((data) => {
        dataToReturn = data.data
    })
    return dataToReturn;
}

export const UpdateHostingDetailshooks = async (data) => {
    try {
        if(data?._id != undefined) {
            let res = await axios.post(API_URL + updatehostingdataApi, data);
            return res;
        }
    } catch (e) {
        console.log('UpdateHostingDetailshooks_err', e)
    }
}


export const getsinglehostingdetailshooks =async (data) => {
    try {
        console.log('consoledata', data)
        let res = await axios.post(API_URL+getsinglehostingdataApi, data);
        return res;
    } catch (e) {
        console.log('getsinglehostingdetailshooks_Err', e)
    }
}



export const getAllHostingDetailhooks = async (data) => {
    let dataToReturn = []
    await axios.get(API_URL + getallhostdataApi , data).then((data) => {
        console.log('respondatedadf', data)
        if (data.data.type == "Success") {
            dataToReturn = data.data.record
        }
    })  
    return dataToReturn
}


export const updateuserdetailsHooks = async (data) => {
    try {
        let dataToReturn = [];
        const formData = new FormData();
        formData.append(`firstName`, data.firstName);
        formData.append(`gender`, data.gender);
        formData.append(`dob`, data.dob);
        formData.append(`govtid`, data.govtid);
        formData.append(`address`, data.address);
        formData.append(`email`, data.email);
        formData.append(`emergency`, data.emergency);
        formData.append(`about`, data.about);
        formData.append(`language`, data.language);
        formData.append(`profilePic`, data.profilePic);
console.log('datadatadata', data, data.profilePic)
// updateuserdetailsApi
        let resp = await axios({        'method': 'post',
        'url': API_URL + updateuserdetailsApi,
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': cookies.get('accessToken') ? `Bearer ${cookies.get('accessToken')}` : ((cookies.get('User-Datas')) ? `Bearer ${(cookies.get('User-Datas')).token}` : "")
        },
        'data': formData,});
        console.log("resp dasd", resp)
        return resp;
    } catch (e) {
        console.log('updateuserdetailsHooks_Errr', e);
    }
}