import React, { useEffect, useState } from "react";
import Footer from "../../../../Common/Footer";
import HostHeader from "../../HostHeader";
import prof from "../../../../../Assets/images/prof.jpg";
import DataTable from "react-data-table-component";
import CancelBook from "./Modal/CancelBook";
import { Tabs, Tab, Button } from "react-bootstrap";

import {
  checkBookingStatus,
  getUserDatas,
} from "../../../../../Hooks/useUserHook";
import {
  getUserBookings,
  getUserCancelledTrips,
  getUserSplitReserveBookings,
  getUserTrips,
} from "../../../../../Hooks/useUserHostingHook";
import { API_URL } from "../../../../../config/env";
// import { getUserDatas } from '../../../../../Hooks/useUserHook';
// import { getUserBookings } from '../../../../../Hooks/useUserHostingHook';
import GiveReview from "../../../../Common/Modals/GiveReivew";
import { useLocation, useNavigate } from "react-router-dom";
import SplitAmountDetails from "./Modal/splitModal";
import { useDispatch, useSelector } from "react-redux";
import { bookingmethod } from "../../../../../redux/action";
import { GrNext, GrPrevious } from "react-icons/gr";
import HeaderDash from "../../../../Common/HeaderDash";
const TripsStay = () => {
  let dispatch = useDispatch();
  const [bookingDatas, setBookingDatas] = useState([]);
  const [tripDatas, setTripDatas] = useState([]);
  const [cancelledDatas, setCancelledDatas] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [cancelDetails, setCancelDetails] = useState({});
  console.log("cancelDetails", cancelDetails);
  const [reload, setReload] = useState(false);
  const [cancelledTab, setCancelledTab] = useState(false);
  const [roomid, setRoomid] = useState("");

  const [splitbookingDatas, setSplitBookingDatas] = useState([]);
  const [upcoming, setUpcoming] = useState(true);
  const [previous, setPrevious] = useState(false);
  const [givereview, setGiveReview] = useState(false);
  const [split, setSplit] = useState(false);
  const [splitamountdata, setSplitAmountData] = useState(false);
  const [viewsplitdata, setViewSplitData] = useState({});
  console.log("splitbookingDatas", splitbookingDatas);
  const navigate = useNavigate();
  let location = useLocation();
  let bookingmethodstatus = useSelector(
    (state) => state?.getUserDataAction?.bookingmethod
  );
  console.log("bookingmethodstatus action.js redux", bookingmethodstatus);
  const [bookingmethoddata, setBookingMethodData] = useState(
    bookingmethodstatus
  );
  useEffect(() => {
    const userdata = getUserDatas();
    console.log("userdata", userdata);
    getUserBookings(userdata.email).then((data) => {
      setBookingDatas(data);
    });
    getUserTrips(userdata.email).then((data) => {
      setTripDatas(data);
    });
    getUserCancelledTrips(userdata.email).then((data) => {
      console.log("UserCancelledTrips", data);
      setCancelledDatas(data);
    });
    getUserSplitReserveBookings(userdata.email).then((data) => {
      setSplitBookingDatas(data);
    });
  }, [reload]);

  console.log("bookingDatasdetails", bookingDatas)
  useEffect(() => {
    console.log(
      "ren action.js redux",
      localStorage.getItem("bookingmethod"),
      localStorage.getItem("bookingmethod") == "splitreservebooking"
    );
    if (localStorage.getItem("bookingmethod") == "splitreservebooking") {
      setSplit(true);
      setUpcoming(false);
    }
  }, [bookingmethoddata]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log("scrolled called");
  }, []);

  const setBookingFilter = async (filter) => {
    try {
      console.log("set action.js redux", filter);
      if (filter) {
        localStorage.setItem("bookingmethod", filter);
        dispatch({ type: bookingmethod, payload: filter });
      }
    } catch (e) {
      console.log("setBookingFilter_err", e);
    }
  };
  const data = [
    {
      id: 1,
      date: "12/04/2022 to 15/08/2022",
      rname: "Dummy Resort",
      rplace: "Madurai, Thirunagar",
      profimg: <img src={prof} />,
      profname: "David",
      btn: (
        <button
          type="button"
          class="btn btn-warning text-light nowrap m-auto"
          //   data-bs-toggle="modal"
          //   data-bs-target="#myModal"
        >
          Processing
        </button>
      ),
    },
    {
      id: 2,
      date: "12/04/2022 to 15/08/2022",
      rname: "Dummy Resort",
      rplace: "Madurai, Thirunagar",
      profimg: <img src={prof} />,
      profname: "David",
      btn: (
        <button
          type="button"
          class="btn btn-success text-light nowrap m-auto"
          // data-bs-toggle="modal"
          // data-bs-target="#myModal"
        >
          Success
        </button>
      ),
      btn2: (
        <button
          type="button"
          class="btn btn-warning text-light nowrap m-auto"
          //  data-bs-toggle="modal"
          //  data-bs-target="#myModal"
        >
          Cancelled
        </button>
      ),
    },
    {
      id: 3,
      date: "12/04/2022 to 15/08/2022",
      rname: "Dummy Resort",
      rplace: "Madurai, Thirunagar",
      profimg: <img src={prof} />,
      profname: "David",
      btn: (
        <button
          type="button"
          class="btn btn-success text-light nowrap m-auto"
          // data-bs-toggle="modal"
          // data-bs-target="#myModal"
        >
          Success
        </button>
      ),
      btn2: (
        <button
          type="button"
          class="btn btn-danger text-light nowrap m-auto"
          //  data-bs-toggle="modal"
          //  data-bs-target="#myModal"
        >
          Cancelled
        </button>
      ),
    },
  ];

  const columns = [
    {
      name: "Host",
      sortable: true,
      reorder: false,
      cell: (data) => (
        <>
          <img
            src={
              data.Host.profilePicturePath
                ? API_URL + "/" + data.Host.profilePicturePath
                : prof
            }
          />
          {data.Host.firstName} {data.Host.lastName}
        </>
      ),
    },
    {
      name: "Date",
      cell: (data) => (
        <>
          {new Date(data.checkInDate).toLocaleDateString()} to{" "}
          {new Date(data.checkOutDate).toLocaleDateString()}
        </>
      ),
      minWidth: "200px",
    },
    {
      name: "Room Title",
      cell: (data) => <>{data?.roomTitle}</>,
      minWidth: "200px",
    },

    {
      name: "Location",
      cell: (data) => (
        <>
          <p>
            {data.accommodationAddress.city}{" "}
            {data.accommodationAddress.countrycode}
          </p>
        </>
      ),
      minWidth: "200px",
    },
    {
      name: "Status",
      button: true,
      cell: (data) => (
        <>
          {/* {(data.isVerified)?
                (data.isValidTransaction?
                    successButton:
                    ((data.expired)?
                expiredButton:
                pendingButton)):
                (data.isRejected?
                    rejectedButton
                    :(data.expired?
                        expiredButton:
                        processingButton))} */}

          <button
            type="button"
            disabled={data.isRejected || data.iscancelled || data.expired}
            class={
              data.isVerified
                ? data.isValidTransaction
                  ? "btn btn-success text-light nowrap m-auto"
                  : data.expired
                  ? "btn btn-secondary text-light nowrap m-auto"
                  : "btn btn-warning text-light nowrap m-auto"
                : data.isRejected
                ? "btn btn-danger text-light nowrap m-auto"
                : data.expired
                ? "btn btn-secondary text-light nowrap m-auto"
                : "btn btn-warning text-light nowrap m-auto"
            }
            onClick={() => {
              data.isVerified
                ? data.isValidTransaction
                  ? navigate("/payment-success/" + data._id, {
                      state: "reservebooking",
                    })
                  : navigate("/confirm-and-pay/" + data._id, {
                      state: "reservebooking",
                    })
                : console.log("pending");
              data.isVerified
                ? data.isValidTransaction
                  ? setBookingFilter("reservebooking")
                  : setBookingFilter("reservebooking")
                : console.log("pending");
            }}
            // data-bs-toggle="modal"
            // data-bs-target="#myModal"
          >
            {data.isVerified
              ? data.isValidTransaction
                ? data.iscancelled
                  ? "cancelled"
                  : "Confirmed"
                : data.expired
                ? "Expired"
                : // Pending
                data.iscancelled
                ? "cancelled"
                : "Pay Now"
              : data.isRejected
              ? "Rejected"
              : data.expired
              ? "Expired"
              : data.iscancelled
              ? "cancelled"
              : "Processing"}
          </button>

          {data.iscancelled ? (
            <></>
          ) : data.expired ? (
            <></>
          ) : (
            <button
              type="button"
              class={
                data.iscancelled
                  ? ""
                  : data.expired
                  ? ""
                  : "btn btn-warning text-light nowrap m-auto"
              }
              onClick={() => {
                data.iscancelled ? console.log("cancelled") : setCancel(true);
                setCancelDetails(data);
                console.log('data details', data)
              }}
              // data-bs-toggle="modal"
              // data-bs-target="#myModal"
            >
              Cancel
            </button>
          )}
        </>
      ),
      minWidth: "250px",
    },
    {
      name: "Option",
      button: true,
      cell: (data) => (
        <button
          type="button"
          class="btn btn-theme nowrap"
          // data-bs-toggle="modal"

          // data-bs-target="#myModal"
          onClick={() => {
            console.log("data.hostEmail :", data.hostEmail);
            navigate(`/host-dashboard/inbox/${data.email}/${data.hostEmail}`, {
              state: data.hostEmail,
            });
          }}
        >
          Message History
          <span className="fa fa-paper-plane ms-1" />
        </button>
      ),
      minWidth: "250px",
    },
  ];

  const columns2 = [
    {
      cell: (data) => (
        <>
          <img
            src={
              data.Host.profilePicturePath
                ? API_URL + "/" + data.Host.profilePicturePath
                : prof
            }
          />
          {data.Host.firstName} {data.Host.lastName}
        </>
      ),
    },
    {
      name: "Date",
      cell: (data) => (
        <>
          {new Date(data.checkInDate).toLocaleDateString()} to{" "}
          {new Date(data.checkOutDate).toLocaleDateString()}
        </>
      ),
      minWidth: "200px",
    },
    {
      name: "Room Title",
      cell: (data) => <>{data?.roomTitle}</>,
      minWidth: "200px",
    },
    {
      name: "Location",
      cell: (data) => (
        <>
          <p>
            {data.accommodationAddress.city}{" "}
            {data.accommodationAddress.countrycode}
          </p>
        </>
      ),
      minWidth: "200px",
    },
    {
      name: "Status",
      button: true,
      cell: (data) => (
        <>
          <button
            type="button"
            // class="btn btn-warning text-light nowrap m-auto"
            class={"btn btn-success text-light nowrap m-auto"}
            // data-bs-toggle="modal"
            // data-bs-target="#myModal"
          >
            Success
          </button>
        </>
      ),
      minWidth: "250px",
    },
    {
      name: "Review",
      button: true,
      cell: (data) => (
        <button
          type="button"
          className="btn btn-theme"
          // class="btn btn-chat nowrap"
          // data-bs-toggle="modal"
          // data-bs-target="#myModal"
          onClick={() => {
            setRoomid(data?.roomId);
            setGiveReview(true);
          }}
        >
          Add Review
        </button>
      ),
      minWidth: "250px",
    },
  ];
  const columns3 = [
    // {
    //     name:"Host",
    //     sortable: true,
    //     reorder: true,
    //     cell: (data) => (
    //         <><img src={(data.Host.profilePicturePath)?API_URL+"/"+data.Host.profilePicturePath:prof} />
    //         {data.Host.firstName} {data.Host.lastName}</> ),

    // },

    {
      name: "Cancellation Id",
      sortable: true,
      reorder: false,
      cell: (data) => <p>{data.cancellationDetails._id}</p>,
    },
    {
      name: "Room Title",
      sortable: true,
      reorder: false,
      cell: (data) => <p>{data.roomTitle}</p>,
    },
    {
      name: "Date",
      cell: (data) => (
        <>
          {new Date(data.checkInDate).toLocaleDateString()} to{" "}
          {new Date(data.checkOutDate).toLocaleDateString()}
        </>
      ),
      minWidth: "200px",
    },
    {
      name: "Booking Id",
      cell: (data) => (
        <>
          <p>{data._id}</p>
        </>
      ),
      minWidth: "200px",
    },
    {
      name: "Status",
      button: true,
      cell: (data) => (
        <>
          <button
            type="button"
            onClick={() => {
              navigate(
                `/host-dashboard/trips/cancellation/${
                  data.cancellationDetails._id
                }`
              );
            }}
            // class="btn btn-warning text-light nowrap m-auto"
            class={"btn btn-success text-light nowrap m-auto"}
            // data-bs-toggle="modal"
            // data-bs-target="#myModal"
          >
            status
          </button>
        </>
      ),
      minWidth: "250px",
    },
    // {
    //     name:"Review",
    //     button: true,
    //     cell: (data) => (
    //       <button
    //         type="button"
    //         class="btn btn-chat nowrap"
    //         data-bs-toggle="modal"
    //         data-bs-target="#myModal"

    //         >
    //     Add Review

    //       </button> ),
    //       minWidth:"250px"
    // },
  ];

  const columns4 = [
    {
      name: "Host",
      sortable: true,
      reorder: false,
      cell: (data) => (
        <>
          <img
            src={
              data.Host.profilePicturePath
                ? API_URL + "/" + data.Host.profilePicturePath
                : prof
            }
          />
          {data.Host.firstName} {data.Host.lastName}
        </>
      ),
    },
    {
      name: "Date",
      cell: (data) => (
        <>
          {new Date(data.checkInDate).toLocaleDateString()} to{" "}
          {new Date(data.checkOutDate).toLocaleDateString()}
        </>
      ),
      minWidth: "200px",
    },
    {
      name: "Room Title",
      cell: (data) => <>{data?.roomTitle}</>,
      minWidth: "200px",
    },

    {
      name: "Location",
      cell: (data) => (
        <>
          <p>
            {data.accommodationAddress.city}{" "}
            {data.accommodationAddress.countrycode}
          </p>
        </>
      ),
      minWidth: "200px",
    },
    {
      name: "SplitAmount",
      cell: (data) => (
        <>
          <button
            type="button"
            class="btn btn-warning text-light nowrap m-auto"
            onClick={() => {
              setSplitAmountData(true);
              setViewSplitData(data);
              handleShow();
            }}
          >
            SplitAmount
          </button>
        </>
      ),
    },
    {
      name: "Status",
      button: true,
      cell: (data) => (
        <>
          {/* {(data.isVerified)?
                (data.isValidTransaction?
                    successButton:
                    ((data.expired)?
                expiredButton:
                pendingButton)):
                (data.isRejected?
                    rejectedButton
                    :(data.expired?
                        expiredButton:
                        processingButton))} */}

          <button
            type="button"
            disabled={data.isRejected || data.iscancelled || data.expired}
            class={
              data.isVerified
                ? data.isValidTransaction
                  ? "btn btn-success text-light nowrap m-auto"
                  : data.expired
                  ? "btn btn-secondary text-light nowrap m-auto"
                  : "btn btn-warning text-light nowrap m-auto"
                : data.isRejected
                ? "btn btn-danger text-light nowrap m-auto"
                : data.expired
                ? "btn btn-secondary text-light nowrap m-auto"
                : "btn btn-warning text-light nowrap m-auto"
            }
            onClick={() => {
              data.isVerified
                ? data.isValidTransaction
                  ? navigate("/payment-success/" + data._id, {
                      state: "splitreservebooking",
                    })
                  : navigate("/confirm-and-pay/" + data._id, {
                      state: "splitreservebooking",
                    })
                : console.log("pending");

              data.isVerified
                ? data.isValidTransaction
                  ? setBookingFilter("splitreservebooking")
                  : setBookingFilter("splitreservebooking")
                : console.log("pending");
            }}
            // data-bs-toggle="modal"
            // data-bs-target="#myModal"
          >
            {console.log("data.isVerified",data.isVerified, data.isValidTransaction)}
            {data.isVerified
              ? data.isValidTransaction
                ? data.iscancelled
                  ? "cancelled"
                  : "Confirmed"
                : data.expired
                ? "Expired"
                : // Pending
                data.iscancelled
                ? "cancelled"
                : "Pay Now"
              : data.isRejected
              ? "Rejected"
              : data.expired
              ? "Expired"
              : data.iscancelled
              ? "cancelled"
              : "Processing"}
          </button>

          {data.iscancelled ? (
            <></>
          ) : data.expired ? (
            <></>
          ) : (
            <button
              type="button"
              class={
                data.iscancelled
                  ? ""
                  : data.expired
                  ? ""
                  : "btn btn-warning text-light nowrap m-auto"
              }
              onClick={() => {
                data.iscancelled ? console.log("cancelled") : setCancel(true);
                setCancelDetails(data);
              }}
              // data-bs-toggle="modal"
              // data-bs-target="#myModal"
            >
              Cancel
            </button>
          )}
        </>
      ),
      minWidth: "250px",
    },
    {
      name: "Option",
      button: true,
      cell: (data) => (
        <button
          type="button"
          class="btn btn-theme nowrap"
          // data-bs-toggle="modal"

          // data-bs-target="#myModal"
          onClick={() => {
            console.log("data.hostEmail :", data.hostEmail);
            navigate(`/host-dashboard/inbox/${data.email}/${data.hostEmail}`, {
              state: data.hostEmail,
            });
          }}
        >
          Message History
          <span className="fa fa-paper-plane ms-1" />
        </button>
      ),
      minWidth: "250px",
    },
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const MblData = [
    {
      host: "First Last",
      date: "11/23/2023",
      title: "Property Name",
      location: "California",
      status: "Active",
      option: "Message History",
    },
    {
      host: "First Last",
      date: "11/23/2023",
      title: "Property Name",
      location: "California",
      status: "Active",
      option: "Message History",
    },
    {
      host: "First Last",
      date: "11/23/2023",
      title: "Property Name",
      location: "California",
      status: "Active",
      option: "Message History",
    },
  ];

  return (
    <div className="trips">
      {splitamountdata && (
        <SplitAmountDetails
          splitbookingDatas={viewsplitdata}
          splitPaymentDetails={viewsplitdata?.splitPaymentDetails}
          show={show}
          handleClose={handleClose}
        />
      )}
      {givereview && (
        <GiveReview roomid={roomid} onDismiss={() => setGiveReview(false)} />
      )}
      <HeaderDash />
      <HostHeader />
      {cancel && (
        <CancelBook
          data={cancelDetails}
          onReload={() => {
            setReload(true);
          }}
          onDismiss={() => {
            setCancel(false);
          }}
        />
      )}
      <div className="container container_custom mh-100vh-header">
        <div className="p-3">
          <div className="d-sm-flex jc-end align-items-center">
            <div className="stay-togglebtn toggle_ho_scr">
              <button
                className={split ? "active btn" : "btn"}
                onClick={() => {
                  setSplit(true);
                  setUpcoming(false);
                  setPrevious(false);
                  setCancelledTab(false);
                }}
              >
                SplitReserve Trip
              </button>
              <button
                className={upcoming ? "active btn" : "btn"}
                onClick={() => {
                  setSplit(false);
                  setUpcoming(true);
                  setPrevious(false);
                  setCancelledTab(false);
                }}
              >
                Upcoming Trip
              </button>
              <button
                className={previous ? "active btn" : "btn"}
                onClick={() => {
                  setSplit(false);
                  setUpcoming(false);
                  setPrevious(true);
                  setCancelledTab(false);
                }}
              >
                Previous Trip
              </button>
              <button
                className={cancelledTab ? "active btn" : "btn"}
                onClick={() => {
                  setSplit(false);
                  setUpcoming(false);
                  setPrevious(false);
                  setCancelledTab(true);
                }}
              >
                Cancelled Trip
              </button>
            </div>
          </div>
        </div>
        <div className="cmn-table-webView mt-4 mb-4">
          <div className="p-3">
            <div className="d-flex jc-between align-items-center">
              <h4 className="mb-0 themeclr1 fw-500">Dispute</h4>
              <button className="btn cmn-bg-orange px-3 fw-500">Create</button>
            </div>
          </div>

          <div className={split ? "disputetab" : "d-none"}>
            <div className="position-relative no-data-bdr-top">
              <DataTable
                className="tripstay_tab1 cmn-data-table"
                pagination
                columns={columns4}
                data={splitbookingDatas}
              />
              <div className="showing-records">Showing 3 of 3 records</div>
            </div>
          </div>
          <div className={upcoming ? "disputetab" : "d-none"}>
            <div className="position-relative no-data-bdr-top">
              <DataTable
                className="tripstay_tab1 cmn-data-table"
                pagination
                columns={columns}
                data={bookingDatas}
              />
              <div className="showing-records">Showing 3 of 3 records</div>
            </div>
          </div>
          <div className={previous ? "disputetab " : "d-none"}>
            <div className="position-relative no-data-bdr-top">
              <DataTable
                className="tripstay_tab2 cmn-data-table"
                pagination
                columns={columns2}
                data={tripDatas}
              />
              <div className="showing-records">Showing 3 of 3 records</div>
            </div>
          </div>
          <div className={cancelledTab ? "disputetab" : "d-none"}>
            <div className="position-relative no-data-bdr-top">
              <DataTable
                className="tripstay_tab3 cmn-data-table"
                pagination
                columns={columns3}
                data={cancelledDatas}
              />
              <div className="showing-records">Showing 3 of 3 records</div>
            </div>
          </div>
        </div>
        <div className="cmn-table-mblView mb-4">
          <div className="d-flex jc-between align-items-center mb-3">
            <h2 className="mb-0 themeclr1 fw-500">Stay</h2>
          </div>
          <div className="datas">
            <div className="row">
              {MblData.map((item, index) => {
                return (
                  <div className="col-12">
                    <div className="table-datas">
                      <p className="mb-2 themeclr1 fw-500">{item.sno}</p>
                      <div className="mb-2">
                        <span className="name">Host: </span>
                        <span className="value">{item.host}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Date: </span>
                        <span className="value">{item.date}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Property Title:: </span>
                        <span className="value">{item.title}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Location: </span>
                        <span className="value">{item.location}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Status: </span>
                        <span className="value">{item.active}</span>
                      </div>
                      <div className="mb-2">
                        <span className="name">Option: </span>
                        <span className="value">{item.option}</span>
                      </div>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
            <div className="prev-next-btn">
              <Button className="">
                <GrPrevious />
              </Button>
              <span className="f-15 fw-400">Showing 3 of 3 records</span>
              <Button className="">
                <GrNext />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TripsStay;
